package gr.web.icyleds.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.FaIceCream
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.*

@Page
@Composable
fun ComingSoonPage() {

    PageLayout("Oops!") {
        Box(
            modifier = Modifier
                .padding(bottom = 30.vh)
                .backgroundColor(Colors.Black)
                .color(Colors.White)
                .fillMaxSize(),
            contentAlignment = Alignment.Center
        ) {

            Column(
                modifier = Modifier
                    .gap(1.cssRem)
                    .width(350.px)
                    .height(500.px),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                Img(
                    src = "icylogo.png",
                    alt = "The Collective Logo",
                    attrs = Modifier
                        .objectFit(ObjectFit.Cover)
                        .width(100.px)
                        .height(100.px)
                        .toAttrs()
                )

               H1(
                   attrs = Modifier
                       .margin(top = 30.px)
                       .padding(0.px)
                       .toAttrs()
               ) {
                   Text("Oops!")
               }

                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    H3(
                        attrs = Modifier
                            .margin(0.px)
                            .padding(0.px)
                            .color(Theme.IcyGold.rgb)
                            .fontWeight(FontWeight.Light)
                            .toAttrs()
                    ) {
                        Text("Cone Back Soon!")
                    }

                    FaIceCream(
                        modifier = Modifier
                            .color(Theme.IcyGold.rgb)
                            .margin(left = 1.cssRem),
                        size = IconSize.XL
                    )
                }


                Link(
                    text = "Back to ICY",
                    path = ROUTES.HOME,

                )

            }


        }



    }

}