package gr.web.icyleds.pages.products

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.defer.Deferred
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.CappedWidth
import gr.web.icyleds.components.Carousels.loadProductImageFlickity
import gr.web.icyleds.components.Carousels.ProductImageCarousel
import gr.web.icyleds.components.PicturePopup
import gr.web.icyleds.components.ProductUIEvents
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.styles.VideoBackground
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Page(routeOverride = "fat-pack-820w")
@Composable
fun FatPack820ProductPage() {
    val breakpoint = rememberBreakpoint()
    val context = rememberPageContext()
    var uiState by remember { mutableStateOf(ProductUIEvents()) }
    val imageList = listOf(RES.FAT_PACK8_1)

    Deferred {
        loadProductImageFlickity()
    }

    PageLayout("FAT PACK 820W") {
        VideoBackground(
            modifier = Modifier
                .fillMaxSize()
                .position(Position.Relative)
                .overflow(Overflow.Hidden)
        ) {

            Row(
                modifier = Modifier
                    .padding(top = 1.cssRem, bottom = 3.cssRem)
                    .zIndex(1),
                horizontalArrangement = Arrangement.Center)
            {
                Column(
                    modifier = Modifier
                        .padding(top = 0.cssRem, bottom = 6.cssRem)
                        .then(CappedWidth.toModifier()),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {

                    /*
                            Products Image Carousel
                     */
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center,
                    ) {

                        ProductImageCarousel(imageList)

                    }

                    /*
                            Product Information
                     */
                    SimpleGrid(
                        numColumns = numColumns(base = 1, sm = 2),
                        modifier = Modifier
                            .padding(top = 5.cssRem)
                            .fillMaxWidth()
                    ) {
                        //Column 1
                        Column(
                            modifier = Modifier
                                .padding(all = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.px)
                                .fillMaxWidth()
                                .textAlign(TextAlign.Center),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {

                            Img(
                                src = RES.FAT_PACK8_LOGO,
                                alt = "FAT PACK 820W Logo",
                                attrs = Modifier
                                    .width(if (breakpoint < Breakpoint.MD) 100.percent else 80.percent )
                                    .height(autoLength)
                                    .objectFit(ObjectFit.ScaleDown)
                                    .toAttrs()
                            )

                            H3(
                                attrs = Modifier
                                    .margin(top = 1.cssRem)
                                    .fontSize(20.px)
                                    .textAlign(TextAlign.Center)
                                    .color(Colors.White)
                                    .toAttrs()
                            ) {
                                Text("FAT PACK 820W LED GROW LIGHT")
                            }

                            Ul(
                                attrs = Modifier
                                    .fontSize(24.px)
                                    .fontFamily(Constants.FONTS.POPPINS)
                                    .listStyle(ListStyleType.Square)
                                    .textAlign(TextAlign.Start)
                                    .fillMaxWidth()
                                    .margin(topBottom = 2.cssRem)
                                    .color(Colors.White)
                                    .gap(2.cssRem)
                                    .toAttrs()
                            ) {
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Full-Cycle, Full-Spectrum light output")
                                }
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Light weight, Low Profile design")
                                }
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Commercial Grade, all aluminum housing for maximum heat dissipation")
                                }
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Dual RJ12 Ports Compatible with 0-10V room control systems")
                                }
                            }

                            HorizontalDivider(Modifier.color(Theme.HalfBlack.rgb))

                            P(
                                attrs = Modifier
                                    .fontSize(18.px)
                                    .fontFamily(Constants.FONTS.POPPINS)
                                    .fontWeight(FontWeight.Normal)
                                    .color(Colors.White)
                                    .textAlign(TextAlign.Justify)
                                    .margin(topBottom = 15.px)
                                    .toAttrs()
                            ) {
                                Text("Icy FAT PACK LED Grow Lights are high-performance, full-cycle solutions designed for indoor and greenhouse commercial cultivation. These top-tier lights offer scalability with a design suited for customized configurations. The FAT PACK optimizes photosynthesis and maximizes yield. With exceptional light output (PPF), precise spectral distribution, and impressive efficiency, you'll achieve outstanding results without breaking the bank.")
                            }
                        }

                        //Column 2
                        Column(
                            modifier = Modifier
                                .padding(all = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.px)
                                .fillMaxWidth(),
                            horizontalAlignment = if (breakpoint < Breakpoint.MD) Alignment.CenterHorizontally else Alignment.End,
                            verticalArrangement = Arrangement.Top
                        ) {
                            Img(
                                src = RES.FAT_PACK8_FACTS,
                                alt = "FAT PACK facts",
                                attrs = Modifier
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        uiState = uiState.copy(imgPopup = true, imgURL = RES.FAT_PACK8_FACTS)
                                    }
                                    .width(if (breakpoint < Breakpoint.MD) 100.percent else 80.percent )
                                    .height(autoLength)
                                    .objectFit(ObjectFit.Contain)
                                    .margin(bottom = 1.cssRem)
                                    .toAttrs()
                            )

                            Img(
                                src = RES.ICY_POP_SPECTRUM,
                                alt = "ICY POP spectrum",
                                attrs = Modifier
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        uiState = uiState.copy(imgPopup = true, imgURL = RES.ICY_POP_SPECTRUM)
                                    }
                                    .width(if (breakpoint < Breakpoint.MD) 100.percent else 85.percent )
                                    .height(autoLength)
                                    .objectFit(ObjectFit.Contain)
                                    .toAttrs()
                            )
                        }
                    }

                    Column(
                        modifier = Modifier
                            .padding(top = 3.cssRem)
                            .fillMaxWidth(),
                        verticalArrangement = Arrangement.Center,
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {

                        SpanText(
                            text = "Dont let the hype melt!",
                            modifier = Modifier
                                .fillMaxWidth()
                                .textAlign(TextAlign.Center)
                                .margin(bottom = 1.cssRem)
                                .fontFamily(Constants.FONTS.BATUPHAT)
                                .letterSpacing(1.px)
                                .fontSize(45.px)
                                .color(Theme.IcyPink.rgb)
                        )

                        Button(
                            attrs = Modifier
                                .then(productColumnButtonStyle.toModifier())
                                .onClick {
                                    context.router.navigateTo(ROUTES.SECTIONS.PRODUCTS_CONTACT)
                                }
                                .toAttrs()
                        ) {
                            Text("CONE-TACT US")
                        }
                    }
                }
            }
        }
    }

    if (uiState.imgPopup) {
        PicturePopup(
            imgURL = uiState.imgURL,
            onDismiss = {
                uiState = uiState.copy(imgPopup = false)
            }
        )
    }

}