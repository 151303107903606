package gr.web.icyleds.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*

@Page("/")
@Composable
fun TermsOfServicePage() {
    gr.web.icyleds.components.layouts.MarkdownLayout("Terms of Service") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("terms-and-conditions") }) {
            org.jetbrains.compose.web.dom.Text("Terms and Conditions")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Effective Date: 1/01/25")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("These Terms of Service (\"Terms\") govern your use of the ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("ICY LEDS")
            }
            org.jetbrains.compose.web.dom.Text(" website and the services provided by us. By using our website, you agree to comply with and be bound by these Terms.")
        }
        org.jetbrains.compose.web.dom.Ol {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Eligibility")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("To use our services and participate in communication, you must be at least 18 years old or the age of majority in your jurisdiction, whichever is older.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Account Registration")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("To use certain features, you may need to create an account. You agree to provide accurate, current, and complete information and to keep this information up to date. You are responsible for maintaining the confidentiality of your account credentials.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Use of the Website")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("You agree not to:")
                }
                org.jetbrains.compose.web.dom.Ul {
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Use the website for any unlawful purpose.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Engage in any activity that disrupts or interferes with the website’s functionality.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Attempt to access another user’s account or information without permission.")
                    }
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Intellectual Property")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("All content on the ")
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("ICY LEDS")
                    }
                    org.jetbrains.compose.web.dom.Text(" website, including text, graphics, logos, and software, is owned by or licensed to us. You agree not to copy, distribute, or modify any content without our express written consent.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Limitation of Liability")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("To the fullest extent permitted by law, ")
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("ICY LEDS")
                    }
                    org.jetbrains.compose.web.dom.Text(" and its affiliates shall not be liable for any indirect, incidental, or consequential damages arising from your use of the website or our services.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Changes to the Service")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We reserve the right to modify or discontinue the website or services at any time, without prior notice.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Termination")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We may terminate or suspend your account and access to the website at our sole discretion, without notice, if you violate these Terms.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Contact Us")
                }
            }
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("what-is-covered") }) {
            org.jetbrains.compose.web.dom.Text("What Is Covered")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Icy LED Grow Lights products are warranted against defects in materials and/or workmanship for a period of three (5) years from the original date of purchase.  During the warranty period, Icy LED will either repair or replace any covered, defective product.  Within the first 90 days, Icy LED will incur all costs associated with the repair, or replacement, and return of the defective product.  After 90 days, the customer will assume responsibility of shipping the defective product back to Icy LED for the remainder of the warranty period.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("what-is-not-covered") }) {
            org.jetbrains.compose.web.dom.Text("What is Not Covered")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("This limited warranty does not cover any damage, deterioration or malfunction resulting from any alteration, modification, improper or unreasonable use or maintenance, misuse, abuse, neglect, exposure to excess moisture, fire, and/or corrosive or damaging substances such as, but not limited to, diatomaceous earth, sulfur dioxide from sulfur burners, and excessive carbon dioxide produced from NG/LP burners, improper packing and shipping (such claims must be presented to the carrier), lightning, power surges, or other acts of nature. This warranty does not cover any damage, deterioration or malfunction resulting from the installation or removal of this product from any installation, any unauthorized tampering with this product, any repairs attempted by anyone unauthorized by Icy LED to make such repairs, or any other cause which does not relate directly to a defect in materials and/or workmanship of this product. If the use of corrosive materials in your grow cannot be avoided, your Icy LED grow light(s) must be removed or completely covered during exposure periods. Damages resultant from corrosive materials are easily identified during inspection and are not covered under this warranty.How to Obtain Warranty ServiceIn order to enforce the rights under this warranty, the original purchaser must notify Icy LED of a warranty claim by contacting a Icy LED representative at 775-235-2633 or ")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:info@icyleds.com. ") {
                org.jetbrains.compose.web.dom.Text("info@icyleds.com. ")
            }
            org.jetbrains.compose.web.dom.Text(" At this time, the original purchaser will be called upon to provide proof of purchase.  The Icy LED representative will either attempt to assist in troubleshooting, request photos showing defect and provide more detailed return instructions.")
        }
        com.varabyte.kobweb.silk.components.layout.HorizontalDivider()
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("If you have any questions about these Terms, please contact us at ")
            com.varabyte.kobweb.silk.components.navigation.Link("mailto:dana@icyleds.com") {
                org.jetbrains.compose.web.dom.Text("dana@icyleds.com")
            }
            org.jetbrains.compose.web.dom.Text(".")
        }
    }
}
