package gr.web.icyleds

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.min
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.layout.HorizontalDividerStyle
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.addVariantBase
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.modifyStyleBase
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*

@InitSilk
fun initSiteStyles(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("html") {
        Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
    }

    ctx.stylesheet.registerStyleBase("body") {
        Modifier
            .fontFamily(
                "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
                "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"
            )
            .fontSize(18.px)
            .lineHeight(1.5)
    }

    // Silk dividers only extend 90% by default; we want full width dividers in our site
    ctx.theme.modifyStyleBase(HorizontalDividerStyle) {
        Modifier.fillMaxWidth()
    }
}

val HeadlineTextStyle = CssStyle {
    base {
        Modifier
            .fontSize(2.cssRem)
            .fontWeight(FontWeight.Bolder)
            .textAlign(TextAlign.Center)
            .lineHeight(1)
            .color(Colors.White)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(3.cssRem)
    }
}

val SubheadlineTextStyle = CssStyle {
    base {
        Modifier
            .fontSize(1.3.cssRem)
            .textAlign(TextAlign.Center)
            .color(Colors.White)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(1.5.cssRem)
    }
}

val CappedWidth = CssStyle {
    base {
        Modifier
            .width(min(1100.px, 100.percent - 3.cssRem))
    }
}

val justifiedText = CssStyle {
    base {
        Modifier
            .textAlign(TextAlign.Justify)
    }
    Breakpoint.ZERO {
        Modifier
            .textAlign(TextAlign.Justify)
    }
    Breakpoint.SM {
        Modifier
            .textAlign(TextAlign.Justify)
    }
}

fun Modifier.noPaddingMargin(): Modifier {
    return Modifier
        .margin(0.px)
        .padding(0.px)
}

val CircleButtonVariant = ButtonStyle.addVariantBase {
    Modifier.padding(0.px).borderRadius(50.percent)
}

val UncoloredButtonVariant = ButtonStyle.addVariantBase {
    Modifier.setVariable(ButtonVars.BackgroundDefaultColor, Colors.Transparent)
}
