package gr.web.icyleds.components.Carousels

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.JustifyContent
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import gr.web.icyleds.components.services.fetchProductData
import gr.web.icyleds.lkpktoolset.Flickity.initializedProductsFlickity
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text

//    val productListOG = listOf(ProductCarouselItem("Vegetable Grow LED Light", "LED Light Indoor farm", sampleText,"/"),
//            ProductCarouselItem("Ultraviolet LED Lamp", "Growing succulents", sampleText,"/"),
//            ProductCarouselItem("LED Bulb in The Forest", "LED bulb growing in nature",  sampleText,"/"),
//            ProductCarouselItem("LED Bulb in The Forest 2.0", "LED bulb growing in nature",  sampleText,"/"),
//            ProductCarouselItem("Ultraviolet LED Lamp 2.0", "Growing succulents", sampleText,"/"))

data class ProductCarouselItem(
    val title: String,
    val topic: String,
    val desc: String,
    val link: String,
    val imgUrl: String
)

val ProductHeadStyle = CssStyle.base {
    Modifier
        .fontSize(1.6.cssRem)
        .fontWeight(FontWeight.Bold)
        .textAlign(TextAlign.Start)
        .lineHeight(1.2) //1.5x doesn't look as good on very large text
}

val ProductSubheadStyle = CssStyle.base {
    Modifier
        .flexGrow(1)
        .fontSize(1.2.cssRem)
        .fontWeight(FontWeight.Light)
        .textAlign(TextAlign.Start)
        .color(Colors.Gray)
        .margin(bottom = 1.2.cssRem)
}

val ProductReadMoreButtonStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = 1.8.cssRem)
            .height(3.cssRem)
            .backgroundColor(Colors.Transparent)
            .border(2.px, LineStyle.Solid, Colors.Black)
            .borderRadius(0.px)
            .color(Colors.Black)
            .width(50.percent)
            .alignSelf(AlignSelf.Start)
            .justifySelf(JustifySelf.End)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
    }
}

/* Container, card, and gallery cell CSS */

val ProductCardContainerStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .justifyContent(org.jetbrains.compose.web.css.JustifyContent.SpaceAround)
            .alignItems(org.jetbrains.compose.web.css.AlignItems.Stretch)
            .gap(2.cssRem)
            .padding(top = 2.cssRem, leftRight = 2.cssRem)
            .fillMaxSize()
    }
}

val ProductGalleryCell = CssStyle {
    base {
        Modifier
            .width(100.percent)
            .height(42.cssRem) //Controlling the gallery height.
    }
    Breakpoint.MD {
        Modifier
            .width(50.percent)
    }
    Breakpoint.LG {
        Modifier
            .width(33.percent)
    }
}

val ProductCardStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .justifyContent(JustifyContent.SpaceBetween)

            .backgroundColor(Colors.WhiteSmoke)
            .border(2.px, LineStyle.Solid, Colors.Transparent)
            .boxShadow(0.px, 10.px, 5.px, 0.px, rgba(100, 100, 111, 0.2))
            .margin(leftRight = 1.cssRem)
            .padding(leftRight = 1.cssRem, topBottom = 1.cssRem)
            .height(40.cssRem)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
    }
    hover {
        Modifier
            .border(2.px, LineStyle.Solid, Colors.Red)
    }
}

@Composable
fun productCard(product: ProductCarouselItem) {
    var isHovering by remember { mutableStateOf(false) }

    Link(product.link, Modifier.textDecorationLine(TextDecorationLine.None)) {
        Div(ProductCardStyle.toModifier().toAttrs() {
            onMouseOver {
                isHovering = true
            }
            onMouseOut {
                isHovering = false
            }
        }) {
            Div(Modifier.display(DisplayStyle.Flex).flexDirection(FlexDirection.Column).fillMaxHeight().toAttrs()) {
                Div(Modifier
                    .backgroundColor(Colors.LightGrey)
                    .maxHeight(250.px)
                    .minHeight(250.px)
                    .fillMaxWidth()
                    .borderRadius(10.px)
                    .margin(bottom = 1.2.cssRem)
                    .toAttrs()) {
                    //Will be replaced with image in the future.
                }

                Div(ProductHeadStyle.toModifier().color(Colors.Black).toAttrs()) {
                    SpanText(product.title)
                }
                Div(ProductSubheadStyle.toModifier().toAttrs()) {
                    SpanText(product.topic)
                }

                Div(ProductSubheadStyle.toModifier().toAttrs()) {
                    SpanText(product.desc)
                }

                Button(onClick = {}, modifier = if (isHovering) {
                    ProductReadMoreButtonStyle.toModifier()
                        .backgroundColor(Colors.Red)
                        .border(1.px, LineStyle.Solid, Colors.WhiteSmoke)
                        .color(Colors.White)
                } else {
                    ProductReadMoreButtonStyle.toModifier()
                }) {
                    Text("READ MORE")
                }
            }
        }
    }
}


@Composable
fun flickProductCarousel(products: List<ProductCarouselItem>) {
    Div(attrs = Modifier
        .classNames("main-carousel", "js-flickity", "flick-prod")
        .fillMaxSize()
        .toAttrs {
            attr("data-flickity", "")
        }) {

        products.forEachIndexed() { _, product ->
            Div(attrs = ProductGalleryCell.toModifier().toAttrs()) {
                productCard(product)
            }
        }
    }
}

@Composable
fun ProductCarousel() {
    val scope = rememberCoroutineScope()
    var productList by remember { mutableStateOf<List<ProductCarouselItem>>(listOf()) }
    var fetchedSuccess by remember { mutableStateOf(true) }

    LaunchedEffect(Unit) {
        scope.launch {
            try {
                productList = fetchProductData()
            } catch (e: Exception) {
                fetchedSuccess = false
                console.error("Error: ${e.message}")
            }
        }
    }

    Row(
        ProductCardContainerStyle.toModifier(),
        horizontalArrangement = Arrangement.Center
    ) {
        if (productList.isNotEmpty()) {
            flickProductCarousel(productList)
            initializedProductsFlickity()
        } else {
            H3() {
                Text("Loading Products...")
            }
        }
    }
}

