package gr.web.icyleds.lkpktoolset.Custom

import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.opacity
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.Theme
import io.ktor.http.*
import org.jetbrains.compose.web.css.*

//https://github.com/HuXn-WebDev/HTML-CSS-JavaScript-100-Projects/blob/main/58.%20Rotating%20Text/style.css
val VertTextCarousel = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .height(70.px)
            .overflow(Overflow.Hidden)
            .display(DisplayStyle.InlineBlock)
            .position(Position.Relative)
    }
    Breakpoint.MD {
        Modifier
            .width(600.px)
    }
}

val VertTextContainer = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Block)
            .position(Position.Relative)
    }
}

val scrollingTextStyle = CssStyle {
    base {
        Modifier
            .fontFamily(Constants.FONTS.HELVETICA)
            .fontSize(24.px)
            .textAlign(TextAlign.Center)
            .lineHeight(80.px)
            .color(Theme.IcyBrightPink.rgb)
            .display(DisplayStyle.Block)
            .textOverflow(TextOverflow.Ellipsis)
            .overflow(Overflow.Hidden)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(38.px)
    }
}

val VertTextScrollAnim = Keyframes {
    0.percent {
        Modifier.top((-5).px)
    }
    20.percent {
        Modifier.top((-5).px) // Move after
    }
    25.percent {
        Modifier.top((-90).px) // Start hold
    }
    45.percent {
        Modifier.top((-90).px) // Move after
    }
    50.percent {
        Modifier.top((-170).px) // Start hold
    }
    70.percent {
        Modifier.top((-170).px) // Move after
    }
    75.percent {
        Modifier.top((-250).px) // Start hold
    }
    95.percent {
        Modifier.top((-250).px) // Move after
    }
    100.percent {
        Modifier.top((-5).px) // Start hold
    }
}