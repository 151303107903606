package gr.web.icyleds.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

val ScoopContainerStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
    }
    Breakpoint.MD {
        Modifier
            .flexDirection(FlexDirection.Row)
    }
}

val ScoopNewsHeadStyle = CssStyle.base {
    Modifier
        .fontSize(1.6.cssRem)
        .fontWeight(FontWeight.Bold)
        .textAlign(TextAlign.Start)
        .lineHeight(1.2) //1.5x doesn't look as good on very large text
}

val ScoopNewsSubheadStyle = CssStyle.base {
    Modifier
        .fontSize(1.2.cssRem)
        .textAlign(TextAlign.Start)
        .color(Colors.Gray)
}

val ReadMoreButtonStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = 1.8.cssRem)
            .height(3.cssRem)
            .backgroundColor(Colors.Transparent)
            .border(2.px, LineStyle.Solid, Colors.Black)
            .borderRadius(0.px)
            .color(Colors.Black)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
    }
    hover {
        Modifier
            .backgroundColor(Colors.Black)
            .color(Colors.White)
    }
}

val ScoopNewsCardStyle = CssStyle.base {
    Modifier
        .backgroundColor(Colors.White)
        .boxShadow(0.px, 7.px, 29.px, 0.px, rgba(100, 100, 111, 0.2))
        .padding(leftRight = 1.cssRem, top = 1.cssRem, bottom = 2.5.cssRem)
}

@Composable
fun singleNewsCard(title: String, desc: String, link: String) {
    Div(ScoopNewsCardStyle.toModifier().toAttrs()) {
        Column(Modifier.gap(1.2.cssRem)) {

            Div(Modifier
                .backgroundColor(Colors.LightGrey)
                .height(20.cssRem)
                .fillMaxWidth()
                .borderRadius(10.px)
                .position(Position.Relative)
                .toAttrs()) {
                //Will be replaced with image in the future.

                Div(Modifier
                    .backgroundImage(url("icecream-redwhite.jpg"))
                    .backgroundPosition(BackgroundPosition.of(CSSPosition.Top))
                    .backgroundRepeat(BackgroundRepeat.NoRepeat)
                    .backgroundSize(BackgroundSize.of(6.cssRem))
                    .width(6.cssRem)
                    .height(6.cssRem)
                    .borderRadius(50.percent)
                    .position(Position.Absolute)
                    .bottom((-20).px)
                    .left(50.percent)
                    .transform { translateX((-50).percent) }
                    .toAttrs()) {
                }
            }


            Div(ScoopNewsHeadStyle.toModifier().color(Colors.Black).toAttrs()) {
                SpanText(title)
            }

            Div(ScoopNewsSubheadStyle.toModifier().toAttrs()) {
                SpanText(desc)
            }

            Button(onClick = {}, modifier = ReadMoreButtonStyle.toModifier()) {
                Text("READ MORE")
            }
        }
    }
}

@Composable
fun newsCardsRow() {
    val sampleText: String = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras laoreet."

    Div(attrs = ScoopContainerStyle.toModifier().fillMaxWidth().gap(2.cssRem).toAttrs()) {
        Column() {
            singleNewsCard("Why is Everyone Talking About Efficiency?", sampleText, "/")
        }
        Column() {
            singleNewsCard("How Do You Choose The Best Growing LED Light?", sampleText, "/")
        }
        Column() {
            singleNewsCard("What kind of Light is Best For Your Plants?", sampleText, "/")
        }
    }
}
