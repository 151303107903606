package gr.web.icyleds.components.Carousels

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img

val ProductImageCarousel = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .margin(leftRight = 0.cssRem, topBottom = 1.cssRem) //For Control room
            .height(450.px)
    }
    Breakpoint.MD {
        Modifier
            .margin(leftRight = 2.cssRem, topBottom = 1.cssRem) //For Control room

    }
}

val ProductImageStyle = CssStyle {
    base {
        Modifier
    }
}

fun loadProductImageFlickity() {
    js("new Flickity('.flick-prodImages', { contain: true, wrapAround: true, pageDots: true, initialIndex: 0, cellAlign: 'left', autoPlay: 4000 })")
}

@Composable
fun ProductImageCarousel(productImages: List<String>) {


    Div(attrs = ProductImageCarousel.toModifier()
        .classNames("main-carousel", "js-flickity", "flick-prodImages")
        .toAttrs {
            attr("data-flickity", "")
        }) {

        productImages.forEachIndexed() { _, imageSrc ->
            Box(
                modifier = Modifier
                    .classNames("carousel-cell")
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .overflow(Overflow.Hidden)
                    .margin(leftRight = 2.cssRem),
                contentAlignment = Alignment.Center
            ) {
                //TODO Dumb Image doesn't want to change right.
                Img(
                    src = imageSrc,
                    alt = "Product Image",
                    attrs = Modifier
                        .objectFit(ObjectFit.ScaleDown)
                        .maxWidth(75.percent)
                        .maxHeight(100.percent)
                        .toAttrs()
                )
            }
        }
    }
}