package gr.web.icyleds.styles

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.Theme
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.w3c.dom.Element
import org.w3c.dom.MutationObserver
import org.w3c.dom.MutationObserverInit
import org.w3c.dom.events.Event

val ScrollImgParagraphSection = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .textAlign(TextAlign.Start)
            .color(Colors.White)
            .margin(topBottom = 2.cssRem)
    }
    Breakpoint.MD {
        Modifier
            .margin(top = 3.cssRem, bottom = 8.cssRem)
    }
}

val ScrollImgParagraphTitleStyle = CssStyle {
    base {
        Modifier
            .fontSize(28.px)
            .letterSpacing(1.px)
            .fontWeight(FontWeight.Bold)
            .display(DisplayStyle.Block)
            .margin(bottom = 30.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(36.px)
            .textAlign(TextAlign.Start)
    }
}

val ScrollImgParagraphStyle = CssStyle {
    base {
        Modifier
            .fontSize(18.px)
            .margin(topBottom = 5.px)
            .color(Colors.WhiteSmoke)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontWeight(FontWeight.Lighter)
            .letterSpacing(.5.px)
            .textAlign(TextAlign.Justify)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(22.px)
            .textAlign(TextAlign.Start)
    }
}

val ScrollImgSpanStyle = CssStyle {
    base {
        Modifier
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(18.px)
            .color(Theme.IcyPink.rgb)
            .textAlign(TextAlign.Start)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(22.px)
    }
}

val ScrollImgContainerStyle = CssStyle {
    base {
        Modifier
            .position(Position.Sticky)
            .top(50.percent)
            .transform { translateY((-40).percent) }
            .margin(top = 15.cssRem)

            .height(500.px)
            .fillMaxWidth()
    }
}


val ScrollImgStyle = CssStyle {
    base {
        Modifier
            .position(Position.Absolute)
            .display(DisplayStyle.Block)
            .height(100.percent)
            .width(100.percent)
            .objectFit(ObjectFit.Contain)
            .opacity(0)
            .transition(Transition.of("ease"), Transition.of("all", 0.2.s))
    }
}

// https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
// Because using top and bottom, this is for vertical checking.
fun isElementInViewport(element: Element): Boolean {
    val rect = element.getBoundingClientRect()
    return rect.top >= 0 && rect.bottom <= window.innerHeight.toDouble()
}

@Composable
fun ScrollingEventListener(updateImage: (Int) -> Unit) {
    val scrollListener: (Event) -> Unit = {
        val paragraphs = listOf(
            document.getElementById("scroll-paragraph1") to 1,
            document.getElementById("scroll-paragraph2") to 2,
            document.getElementById("scroll-paragraph3") to 3,
            document.getElementById("scroll-paragraph4") to 4,
            document.getElementById("scroll-paragraph5") to 5,
            document.getElementById("scroll-paragraph6") to 6,
            document.getElementById("scroll-paragraph7") to 7,
        )

        for ((element, index) in paragraphs) {
            if (element != null && isElementInViewport(element)) {
                updateImage(index)
                break
            }
        }
    }

    //Run code when called.
    //On key change, it runs onDispose{}
    DisposableEffect(window.location.pathname) {
        if (window.location.pathname == "/") {
            window.addEventListener("scroll", scrollListener)
        }

        // Cleanup when the path changes
        onDispose {
            window.removeEventListener("scroll", scrollListener)
        }
    }
}

