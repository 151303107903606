package gr.web.icyleds.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.css.functions.min
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaIceCream
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.CappedWidth
import gr.web.icyleds.HeadlineTextStyle
import gr.web.icyleds.SitePalettes
import gr.web.icyleds.SubheadlineTextStyle
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.components.sections.KeyFeaturesColumns
import gr.web.icyleds.components.Carousels.ReviewCarousel
import gr.web.icyleds.pages.products.ProductPageHeaderBackground
import gr.web.icyleds.pages.products.productColumnButtonStyle
import org.jetbrains.compose.web.dom.Button
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Page
@Composable
fun AboutUsPage() {
    val breakpoint = rememberBreakpoint()
    val context = rememberPageContext()

    PageLayout("About Us") {


        Column(
            modifier = Modifier
                .then(ProductPageHeaderBackground.toModifier())
                .fillMaxWidth()
                .padding(bottom = 2.cssRem)
                .backgroundColor(Theme.IcyGold.rgb),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            //Page Title
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        top = if (breakpoint < Breakpoint.MD) 2.cssRem else 2.cssRem,
                        bottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 2.cssRem
                    ),
                horizontalArrangement = Arrangement.Center
            ) {
                Column(
                    modifier = Modifier
                        .gap(if (breakpoint < Breakpoint.MD) 0.cssRem else 1.cssRem)
                        .then(CappedWidth.toModifier()),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Div(
                        HeadlineTextStyle.toModifier()
                            .margin(bottom = 8.px)
                            .fontFamily(Constants.FONTS.MACHINARY)
                            .fontWeight(FontWeight.Normal)
                            .color(Theme.IcyGold.rgb)
                            .fontSize(if (breakpoint < Breakpoint.MD) 58.px else 78.px)
                            .letterSpacing(0.px)
                            .toAttrs()
                    ) {
                        SpanText("Our Scoop")
                    }

                    Div(
                        SubheadlineTextStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .fontStyle(FontStyle.Italic)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        SpanText(
                            text = "All About us!",
                            modifier = Modifier
                                .fontSize(24.px)
                                .display(DisplayStyle.InlineBlock)
                        )
                    }
                }
            }

            //About Box
            Div(
                attrs = Modifier
                    .width(min(1100.px, 100.percent))
                    .backgroundColor(Theme.HalfBlack.rgb)
                    .color(Colors.WhiteSmoke)
                    .borderRadius(8.px)
                    .padding(20.px)
                    .toAttrs()
            ) {
                H3(
                    attrs = aboutUsHeader.toModifier()
                        .margin(topBottom = 16.px)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .toAttrs()
                ) {
                    Text("Welcome to Icy LED")
                }

                P(
                    attrs = aboutUsParagraph
                        .toAttrs()
                ) {
                    Text("We are stoked to have you here. Our team knows that shopping for Grow Lights can be tedious. If you’ve been struggling to learn which metrics to prioritize, which type of Grow Light will be best suited for your particular setup, or how you may be eligible to get up to 100% of your lighting costs covered, you have come to the right site. As a group of experienced growers, we don’t just know this industry, we’ve helped build it. Now we want to help you navigate it.")
                }

                P(
                    attrs = aboutUsParagraph.toModifier()
                        .margin(top = 18.px)
                        .toAttrs()
                ) {
                    Text("At Icy, we’re here to help you select the perfect option in the endless sea of sprinkles that is the online market place. We’re here to offer you Reliable Products that will withstand your growing environments without failure and Superior Customer Service to supplement our state of the art technology. At the end of the day, we want you to succeed as much as you want to succeed. This brand has been founded on the belief that when a handful of smart, kind individuals put their minds together, great things happen. We knew sharing our success outside of the collective was a must, but none of us wanted to spend time away from doing the things we love to run a brand. We had the knowledge, the proof of concept, the tech, the know-how, and in 2024, we needed the push to bring our vision to the world.")
                }

                P(
                    attrs = aboutUsParagraph.toModifier()
                        .fontStyle(FontStyle.Italic)
                        .margin(topBottom = 18.px)
                        .toAttrs()
                ) {
                    Text("Who could give us such a push...")
                }

                H3(
                    attrs = aboutUsHeader.toModifier()
                        .fontFamily(Constants.FONTS.POPPINS)
                        .margin(topBottom = 16.px)
                        .toAttrs()
                ) {
                    Text("Meet Dana")
                }

                P(
                    attrs = aboutUsParagraph
                        .toAttrs()
                ) {
                    Text("Dana is the smart, kind, fun, PASSIONATE individual that runs the day-to-day operations at Icy LED. If Dana were a light, her energy rating would be record breaking. While her background is mostly in pharmaceuticals, her passion is not limited by one specific sector. She loves helping others, she endorses ethical behavior, and she honors brilliance. When our universe collided with Dana’s we knew she was going to be the perfect person to help get this scoop off the spoon. She understood and honored our vision for Icy from the jump. She has taken the time to get to know us, our history, our mission, and ultimately became the force which brought our dream to reality. She is diligent in her work and eloquent in her interactions. She is motivated by success and excited to work with you.")
                }

                Row(Modifier
                    .margin(top = 1.cssRem)
                    .fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center
                ) {
                    Button(
                        attrs = Modifier
                            .then(productColumnButtonStyle.toModifier())
                            .onClick {
                                context.router.navigateTo(ROUTES.OUR_FLAVORS)
                            }
                            .toAttrs()
                    ) {
                        Text("OUR FLAVORS")
                    }
                }
            }
        }
    }
}

val aboutUsParagraph = CssStyle {
    base {
        Modifier
            .fontSize(18.px)
            .fontFamily(Constants.FONTS.POPPINS)
            .lineHeight(1.75)
            .margin(bottom = 6.px)
            .padding(0.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(24.px)
    }
}

val aboutUsHeader = CssStyle {
    base {
        Modifier
            .fontFamily(Constants.FONTS.HELVETICA)
            .fontSize(32.px)
            .letterSpacing(1.px)
            .margin(topBottom = 2.cssRem)

    }
    Breakpoint.MD {
        Modifier
            .fontSize(54.px)
    }
}

/*
    Unused/Scrapped for current ICY Version.
    Contains: User review section, Why choose ICY columns.
 */
//        Row(SubSectionContainerStyle.toModifier().backgroundColor(SitePalettes.light.brand.purpleAccent), horizontalArrangement = Arrangement.Center) {
//            Column(Modifier.gap(1.cssRem).fillMaxWidth().margin(leftRight = 1.em), horizontalAlignment = Alignment.CenterHorizontally) {
//                Div(HeadlineTextStyle.toModifier().color(Colors.White).toAttrs()) {
//                    SpanText("Why Choose ICY LED Lighting?")
//                }
//
//                Div(SubheadlineTextStyle.toModifier().margin(bottom = 1.5.cssRem).color(Colors.White).opacity(0.8).toAttrs()) {
//                    SpanText("List of Key Features/Benefits of ICY LED Lights, with a Dash of Ice Cream Fun")
//                }
//
//                Row(Modifier.fillMaxWidth()) {
//                    KeyFeaturesColumns()
//                }
//
//                Button(onClick = {}, LearnMoreButtonStyle.toModifier()) {
//                    Text("LEARN MORE")
//                }
//            }
//        }

//        Row(SubSectionContainerStyle.toModifier().then(pinkWaffleBackground.toModifier()).padding(), horizontalArrangement = Arrangement.Center) {
//            Column(carouselSectionColumns.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
//                Div(HeadlineTextStyle.toModifier().padding(top = 5.cssRem, bottom = 5.cssRem).color(Colors.White).toAttrs()) {
//                    SpanText("What Our Customers Say: ICY Lovers Speak Up!")
//                }
//
//                Row(Modifier.fillMaxWidth().fillMaxHeight().gap(5.px).margin(bottom = 4.cssRem)) {
//                    ReviewCarousel()
//                }
//            }
//        }