package gr.web.icyleds.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*

@Page("/")
@Composable
fun PrivacyPolicyPage() {
    gr.web.icyleds.components.layouts.MarkdownLayout("Privacy Policy") {
        org.jetbrains.compose.web.dom.H1(attrs = { id("privacy-policy") }) {
            org.jetbrains.compose.web.dom.Text("Privacy Policy")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Effective Date: 1/01/25")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("At ")
            org.jetbrains.compose.web.dom.B {
                org.jetbrains.compose.web.dom.Text("ICY LEDS")
            }
            org.jetbrains.compose.web.dom.Text(" (hereinafter referred to as \"we,\" \"our,\" or \"us\"), we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website and interact with our services.")
        }
        org.jetbrains.compose.web.dom.Ol {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Information We Collect")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We collect the following types of information:")
                }
                org.jetbrains.compose.web.dom.Ul {
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Personal Information: This may include your name, email address, phone number, mailing address.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Usage Data: We may collect information such as your IP address, browser type, the pages you visit, and how you interact with our website.")
                    }
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("How We Use Your Information")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We use the information we collect to:")
                }
                org.jetbrains.compose.web.dom.Ul {
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Improve and optimize the functionality of our website.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Communicate with you regarding our services.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Comply with legal obligations.")
                    }
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Sharing Your Information")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We will not sell, rent, or share your personal information with third parties, except:")
                }
                org.jetbrains.compose.web.dom.Ul {
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("With service providers who assist us in operating our website and processing rebates (e.g., payment processors).")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("If required by law, to comply with a legal obligation or to protect our rights.")
                    }
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Cookies and Tracking Technologies")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We use cookies and similar tracking technologies to enhance your experience on our website.")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Cookies help us understand how you use our site and improve its functionality.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Data Security")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet is 100% secure.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Your Rights")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("You have the right to:")
                }
                org.jetbrains.compose.web.dom.Ul {
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Access, correct, or delete your personal information.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Opt-out of receiving marketing communications from us.")
                    }
                    org.jetbrains.compose.web.dom.Li {
                        org.jetbrains.compose.web.dom.Text("Request a copy of the personal data we have on file.")
                    }
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Changes to This Policy")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.")
                }
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("Contact Us")
                }
                org.jetbrains.compose.web.dom.P {
                    org.jetbrains.compose.web.dom.Text("If you have any questions about these Terms, please contact us at ")
                    com.varabyte.kobweb.silk.components.navigation.Link("mailto:dana@icyleds.com") {
                        org.jetbrains.compose.web.dom.Text("dana@icyleds.com")
                    }
                    org.jetbrains.compose.web.dom.Text(".")
                }
            }
        }
    }
}
