package gr.web.icyleds.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Img

data class ProductUIEvents(
    val imgPopup: Boolean = false,
    val imgURL: String = "",
)

@Composable
fun PicturePopup(
    imgURL: String,
    onDismiss: () -> Unit
) {
    val breakpoint = rememberBreakpoint()

    Box(
        modifier = Modifier
            .width(100.vw)
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(19)
            .onClick { onDismiss() },
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .backgroundColor(Theme.HalfBlack.rgb)
        )

        Img(
            src = imgURL,
            alt = "Popup image",
            attrs = Modifier
                .padding(if (breakpoint < Breakpoint.MD) 1.cssRem else 10.cssRem)
                .objectFit(ObjectFit.ScaleDown)
                .fillMaxSize()
                .toAttrs()
        )
    }
}