package gr.web.icyleds.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.*
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.components.sections.*
import gr.web.icyleds.components.widgets.cleanLink
import gr.web.icyleds.lkpktoolset.Custom.VertTextCarousel
import gr.web.icyleds.lkpktoolset.Custom.VertTextContainer
import gr.web.icyleds.lkpktoolset.Custom.VertTextScrollAnim
import gr.web.icyleds.lkpktoolset.Custom.scrollingTextStyle
import gr.web.icyleds.styles.*
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import kotlinx.browser.document
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLElement

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base {
        Modifier
            .minHeight(90.svh)
            .fillMaxWidth()
            .gap(1.cssRem)
            .padding(top = 8.cssRem, bottom = 15.cssRem)
    }
    Breakpoint.MD {
        Modifier
            .padding(top = 10.cssRem, bottom = 15.cssRem)
    }
}

val SubSectionContainerStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .gap(1.cssRem)
            .padding(top = 2.cssRem, bottom = 4.cssRem)
    }
    Breakpoint.MD {
        Modifier
            .padding(top = 4.cssRem, bottom = 8.cssRem)
    }
}

val carouselSectionColumns = CssStyle {
    base {
        Modifier
            .gap(1.cssRem)
            .fillMaxWidth()
    }
    Breakpoint.MD {
        Modifier
            .margin(leftRight = 2.cssRem)
    }
}

val ScoopCollectiveBorder = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Black)
            .top(0.px)
            .margin(top = (-10).px)
            .height(10.px)
            .fillMaxWidth()
            .position(Position.Absolute)
    }
}

val ScoopCollectivePlaqueStyle = CssStyle {
    base {
        Modifier
            .objectFit(ObjectFit.Cover)
            .width(100.percent)
            .height(autoLength)
            .cursor(Cursor.Pointer)
            .transition(
                Transition.all(0.25.s)
            )
    }
}


/* Button styling */

val ShopNowButtonStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = .5.em)
            .height(3.em)
            .backgroundColor(Theme.IcyGold.rgb)
            .borderRadius(5.px)
            .boxShadow(0.px, 7.px, 29.px, 0.px, rgba(100, 100, 111, 0.8))
            .color(Theme.HalfBlack.rgb)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
            .fontSize(1.cssRem)
            .position(Position.Relative)
            .cursor(Cursor.Pointer)
            .color(Colors.White)
            .letterSpacing(2.px)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(1.2.cssRem)
            .padding(leftRight = 2.cssRem)
            .height(3.cssRem)
    }
}


val KnowUsButtonStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = 3.cssRem)
            .height(3.cssRem)
            .backgroundColor(Colors.White)
            .border(2.px, LineStyle.Solid, Colors.Black)
            .borderRadius(0.px)
            .color(Colors.Black)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
    }
    hover {
        Modifier
            .opacity(0.5)
            .backgroundColor(SitePalettes.light.brand.pinkAccent)
    }
}

val LearnMoreButtonStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = 3.cssRem)
            .height(3.cssRem)
            .backgroundColor(Colors.Transparent)
            .border(2.px, LineStyle.Solid, SitePalettes.light.brand.tanAccent)
            .borderRadius(0.px)
            .color(Colors.White)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
    }
    hover {
        Modifier
            .backgroundColor(SitePalettes.light.brand.tanAccent)
    }
}

/* Background images for homepage */

val headerBackground = CssStyle {
    base {
        Modifier
            .background(
                Background.of( //Icy Cream BG
                    image = BackgroundImage.of(url(RES.ICE_CREAM_BG)),
                    repeat = BackgroundRepeat.NoRepeat,
                    position = BackgroundPosition.of(CSSPosition.Bottom),
                    size = BackgroundSize.Cover,
                ),
//                Background.of( //Gradient from navbar
//                    image = linearGradient(LinearGradient.Direction.ToTop) {
//                        add(Color.rgba(255, 255, 255, 0.15f), 60.percent)
//                        add(Color.rgb(0x000000), 100.percent)
//                    }.toImage()
//                ),
                Background.of( //Darker Image
                    image = linearGradient(LinearGradient.Direction.ToTop) {
                        add(rgba(0,0,0, 0.4), 100.percent)
                        add(Colors.Black, 100.percent)
                    }.toImage()
                )
            )
    }
}

val pinkWaffleBackground = CssStyle {
    base {
        Modifier
            .backgroundImage(url(RES.PINK_WAFFLE_BG))
            .backgroundRepeat(BackgroundRepeat.RepeatX)
            .backgroundSize(BackgroundSize.Cover)
            .backgroundPosition(BackgroundPosition.of(CSSPosition.Top))
    }
    Breakpoint.MD {
        Modifier
            .backgroundSize(BackgroundSize.Unset)
    }
}

val whiteIceCreamBackground = CssStyle {
    base {
        Modifier
            .backgroundImage(url(RES.WHITE_ICECREAM_BG))
            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
            .backgroundSize(BackgroundSize.Cover)
    }
}

val TheScoopBG = CssStyle {
    base {
        Modifier
            .background(
                Background.of( //Icy Cream BG
                    image = BackgroundImage.of(url(RES.ICECREAM_SCOOP)),
                    repeat = BackgroundRepeat.NoRepeat,
                    position = BackgroundPosition.of(CSSPosition.Bottom),
                    size = BackgroundSize.Cover,
                ),
                Background.of( //Darker Image
                    image = linearGradient(LinearGradient.Direction.ToTop) {
                        add(rgba(0,0,0, 0.3), 100.percent)
                        add(Colors.Black, 100.percent)
                    }.toImage()
                )
            )
    }
}


@Page
@Composable
fun HomePage() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()

    //Scroll Event for 'Growing Tree Section'
    var paragraphInView by remember { mutableStateOf(1) }
    ScrollingEventListener { ParagraphNumber ->
        paragraphInView = ParagraphNumber
    }


    PageLayout("") {

        /*      -- Hero Section --     */
        Row(HeroContainerStyle.toModifier()
            .id("index-hero-container")
            .minHeight(80.lvh)
            .then(headerBackground.toModifier())
            .backgroundColor(Colors.Black),
            horizontalArrangement = Arrangement.Center
        ) {

            Column(Modifier
                .gap(if (breakpoint < Breakpoint.MD) 0.cssRem else 1.cssRem),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                Div(HeadlineTextStyle.toModifier()
                    .fontWeight(FontWeight.Normal)
                    .fontFamily(Constants.FONTS.MACHINARY)
                    .fontSize(if (breakpoint < Breakpoint.MD) 58.px else 95.px)
                    .toAttrs()
                ) {
                    SpanText("Treat Yourself")
                }

                Div(
                    attrs = VertTextCarousel.toAttrs()
                ) {
                    Div(attrs = VertTextContainer.toModifier()
                        .animation(
                            VertTextScrollAnim.toAnimation(
                                duration = 10.s,
                                iterationCount = AnimationIterationCount.Infinite,
                                timingFunction = AnimationTimingFunction.Ease
                        ))
                        .toAttrs()
                    ) {
                        SpanText("TO THE NEWEST FLAVOR", scrollingTextStyle.toModifier())
                        SpanText("TO THE SWEETEST SPECTRUM", scrollingTextStyle.toModifier())
                        SpanText("TO THE TASTIEST HARVEST", scrollingTextStyle.toModifier())
                    }
                }

                Div(
                    attrs = Modifier
                        .position(Position.Relative)
                        .margin(top = 16.px)
                        .zIndex(1)
                        .toAttrs()
                ) {
                    Box(
                        modifier = ShopNowButtonStyle.toModifier()
                            .classNames("toBorder")
                            .onClick {
                                context.router.navigateTo(ROUTES.OUR_FLAVORS)
                            },
                        contentAlignment = Alignment.Center
                    ) {
                        SpanText("TASTE THE SPECTRUM",
                            Modifier
                            .textAlign(TextAlign.Center)
                            .fontWeight(FontWeight.ExtraBold)
                            .classNames("toBorder"))
                    }
                }
            }
        }

        /*      -- The Scoop Section --     */
        Row(SubSectionContainerStyle.toModifier()
            .position(Position.Relative) // For Floating Badge
            .then(TheScoopBG.toModifier()),
            horizontalArrangement = Arrangement.Center
        ) {

            // Collective Border and Image
            Row(
                modifier = ScoopCollectiveBorder.toModifier(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                Box(
                    modifier = Modifier
                        .height(100.percent)
                        .width(200.px)
                        .backgroundColor(Colors.Transparent),
                    contentAlignment = Alignment.Center
                ) {
                    Img(
                        src = RES.GROWERS_COL_PLAQUE,
                        alt = "The Collective Logo",
                        attrs = ScoopCollectivePlaqueStyle
                            .toAttrs()
                    )
                }
            }

            Column(Modifier
                .margin(top = 3.cssRem)
                .then(CappedWidth.toModifier()),
                horizontalAlignment = Alignment.CenterHorizontally)
            {
                Div(HeadlineTextStyle.toModifier()
                    .fillMaxWidth()
                    .margin(bottom = 1.cssRem)
                    .textAlign(TextAlign.Start)
                    .color(Colors.White)
                    .fontWeight(FontWeight.Medium)
                    .fontFamily(Constants.FONTS.POPPINS)
                    .toAttrs()
                ) {
                    SpanText("The Scoop")
                }

                Div(
                    SubheadlineTextStyle.toModifier()
                        .margin(bottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .color(Colors.White)
                        .toAttrs()
                ) {
                    P(justifiedText.toModifier()
                        .margin(0.px)
                        .toAttrs()) {
                        Text("The Cannabis Industry is all about growth. If the cannabis festivals, competitions, trade shows, cultivation summits, and online communities are the light sources, then Icy LED is the product that has been harvested.")
                    }
                }

                Div(
                    SubheadlineTextStyle.toModifier()
                        .margin(bottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .color(Colors.White).toAttrs()
                ) {
                    P(justifiedText.toModifier()
                        .margin(0.px)
                        .toAttrs()) {
                        Text("Unlike your typical run-of-the-mill lighting company, Icy LED was founded and accidentally created by the very audience it attracts. The craziest thing about this company is that it was never supposed to be a company, but sometimes that’s just how the chocolate chips fall.")
                    }
                }

                Div(
                    SubheadlineTextStyle.toModifier()
                        .margin(bottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .color(Colors.White).toAttrs()
                ) {
                    P(justifiedText.toModifier()
                        .margin(0.px)
                        .toAttrs()) {
                        Text("With literally hundreds of years of combined industry experience among us, we know being a grower isn't always whipped cream and sprinkles.  To avoid the worst parts of buying grow equipment at ridiculous markups, dealing with out-of-touch company sales depts, questionable tech, and tedious quoting negotiations, we banded together to go straight to the source and build our own equipment for exclusive use in our own operations.")
                    }
                }

                Div(
                    SubheadlineTextStyle.toModifier()
                        .margin(bottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .color(Colors.White).toAttrs()
                ) {
                    P(justifiedText.toModifier()
                        .margin(0.px)
                        .toAttrs()) {
                        Text("We’ve had a lot of success by working together. We want to share the benefit of our operation with the public simply because it’s the right thing to do.")
                    }
                }

                Div(
                    SubheadlineTextStyle.toModifier()
                        .margin(bottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .color(Colors.White).toAttrs()
                ) {
                    P(justifiedText.toModifier()
                        .margin(0.px)
                        .toAttrs()) {
                        Text("Icy LED provides custom grow lights, built to a higher standard by a higher standard. Grab a spoon and taste the spectrum with Icy LED.")
                    }
                }

                Div(
                    SubheadlineTextStyle.toModifier()
                        .fillMaxWidth()
                        .textAlign(TextAlign.Start)
                        .margin(topBottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .color(Colors.White).toAttrs()
                ) {
                    P(justifiedText.toModifier()
                        .margin(0.px)
                        .toAttrs()) {
                        Text("-CGC")
                    }
                }

                Button(onClick = {}, modifier = KnowUsButtonStyle.toModifier()) {
                    cleanLink("/about", "KNOW MORE ABOUT US")
                }
            }

            // The Collective Stamp in bottom right on BRKPNT.MD >=
            Box(
                modifier = Modifier
                    .displayIfAtLeast(Breakpoint.MD)
                    .position(Position.Absolute)
                    .bottom(50.px)
                    .right(calc { 10.percent + 20.px })
                    .opacity(75.percent),
                contentAlignment = Alignment.Center
            ) {
                Img(
                    src = RES.GROWERS_COL_STAMP,
                    alt = "The Collective Logo",
                    attrs = Modifier
                        .objectFit(ObjectFit.Cover)
                        .rotate(30.deg)
                        .height(180.px)
                        .width(180.px)
                        .toAttrs()
                )
            }
        }

        /*      -- 3UP Scroll Section --     */
        Row(SubSectionContainerStyle.toModifier()
            .id(ROUTES.SECTIONS.HOME_TECH)
            .backgroundColor(SitePalettes.light.brand.purpleAccent),
            horizontalArrangement = Arrangement.Center
        ) {
            SimpleGrid(
                numColumns = numColumns(base = 1, sm = 2),
                modifier = CappedWidth.toModifier().scrollBehavior(ScrollBehavior.Smooth).position(Position.Relative)
            ) {
                //Tracker column
                Column(
                    modifier = Modifier
                        .top(0.px)
                        .margin(top = (-140).px)
                        .height(78.percent)
                        .backgroundColor(Colors.Red)
                        .fontSize(40.px)
                        .color(Colors.White)
                        .opacity(0)
                        .position(Position.Absolute),
                    verticalArrangement = Arrangement.SpaceBetween
                ) {
                    SpanText("1", Modifier.id("scroll-paragraph1"))
                    SpanText("2", Modifier.id("scroll-paragraph2"))
                    SpanText("3", Modifier.id("scroll-paragraph3"))
                    SpanText("4", Modifier.id("scroll-paragraph4"))
                    SpanText("5", Modifier.id("scroll-paragraph5"))
                    SpanText("6", Modifier.id("scroll-paragraph6"))
                    SpanText("7", Modifier.id("scroll-paragraph7"))
                }

                //Paragraph Column
                Column(
                    Modifier
                        .scrollBehavior(ScrollBehavior.Smooth)
                        .fillMaxWidth(),
                ) {

                    //Paragraph 1
                    Div(ScrollImgParagraphSection.toModifier()
                        .toAttrs()) {
                        SpanText("Our Complete Understanding", ScrollImgParagraphTitleStyle.toModifier())
                        P(ScrollImgParagraphStyle.toAttrs()) {
                            Text("At ICY, we prioritize experience. Not only has our team worked with cannabis at every stage, but we have also worked with Growers at every stage. Whether you’re just starting to germinate, or you’ve been flowering for years, we want to work with you! At ICY, reliability and expertise are heavily scooped in to every interaction. When it comes to creating an optimal environment for cannabis growth, we know there is no shortage of nuance. ICY is committed to staying up-to-date on all of the latest and greatest innovations in the lighting and cannabis industries. Choosing to work with ICY’s wealth of experience and exceptional service is the easiest way to promote the growth of your business.")
                        }
                    }

                    //Paragraph 2
                    Div(ScrollImgParagraphSection.toModifier()
                        .toAttrs()) {
                        SpanText("Our Collaborative Approach", ScrollImgParagraphTitleStyle.toModifier())
                        P(ScrollImgParagraphStyle.toAttrs()) {
                            Text("We know the lighting and cannabis industries backwards, forwards, upside down and inside out! Formulating the best approach starts with knowing YOU. To be able to provide you with exceptional service, we need to learn about you.")
                        }
                        Column(
                            modifier = Modifier
                                .margin(topBottom = 20.px)
                                .gap(if (breakpoint < Breakpoint.MD) 10.px else 0.px),
                            horizontalAlignment = Alignment.Start,
                            verticalArrangement = Arrangement.Center
                        ) {
                            SpanText("What are your goals?", ScrollImgSpanStyle.toModifier())
                            SpanText("What’s worked for you in the past?", ScrollImgSpanStyle.toModifier())
                            SpanText("What hasn’t worked for you in the past?", ScrollImgSpanStyle.toModifier())
                            SpanText("What thoughts and opinions would you like to share?", ScrollImgSpanStyle.toModifier())
                            SpanText("Would you like a cup or a cone?", ScrollImgSpanStyle.toModifier()
                                .margin(top = 12.px)
                                .color(Theme.IcyGold.rgb)
                                .fontStyle(FontStyle.Italic))
                        }
                        P(ScrollImgParagraphStyle.toAttrs()) {
                            Text("These are just some of the initial pieces of the puzzle that you can provide to help us know you. Once we gather all the information you want to share, we will provide you with expertly crafted recommendations to review. We will assign a team of individuals that will be at your disposal. These ICY experts will help ensure everything is sailing as smoothly as soft serve.")
                        }
                    }

                    //Paragraph 3
                    Div(ScrollImgParagraphSection.toModifier()
                        .toAttrs()) {
                        SpanText("Our Revolutionary Technology", ScrollImgParagraphTitleStyle.toModifier())
                        P(ScrollImgParagraphStyle.toAttrs()) {
                            Text("The physical products ICY offers to our customers really are the cherry on top! The knowledge our team is equipped with has helped us produce the best LED lighting options available. The physical products ICY offers to our customers really are the cherry on top! The knowledge our team is equipped with has helped us produce the best LED lighting options available.")
                        }
                        Ul(
                            attrs = Modifier
                                .padding(left = 15.px)
                                .gap(5.px)
                                .textAlign(TextAlign.Start)
                                .fontSize(16.px)
                                .fontWeight(FontWeight.Light)
                                .color(Colors.WhiteSmoke)
                                .listStyle(ListStyleType.of("-  "))
                                .toAttrs()
                        ) {
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("DLC Listed, ETL Certified")
                            }
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("Designed for Maximum Rebate Eligibility")
                            }
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("Commercial Grade Construction")
                            }
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("Top Performing Heat Dissipation")
                            }
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("IP65 Rated for Wet Environments")
                            }
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("Full Spectrum, Full-Cycle Application")
                            }
                            Li(
                                attrs = Modifier
                                    .toAttrs()
                            ) {
                                Text("Plug and Play Installation")
                            }
                        }
                    }
                }

                //Image Column
                Column(
                    Modifier
                        .displayIfAtLeast(Breakpoint.MD)
                        .fillMaxSize(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                ) {
                    Div(ScrollImgContainerStyle.toAttrs()) {
                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 1)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase1.png"
                        )

                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 2)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase2.png"
                        )

                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 3)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase3.png"
                        )

                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 4)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase4.png"
                        )

                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 5)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase5.png"
                        )

                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 6)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase6.png"
                        )

                        Img(
                            attrs = ScrollImgStyle.toModifier()
                                .opacity(if (paragraphInView == 7)  100 else 0)
                                .toAttrs(),
                            src = "tree/phase7.png"
                        )
                    }
                }
            }
        }

        /*      -- Home Contact Section --     */
        Row(SubSectionContainerStyle.toModifier()
            .id("contact-us")
            .then(pinkWaffleBackground.toModifier()),
            horizontalArrangement = Arrangement.Center)
        {
            Column(Modifier
                .then(CappedWidth.toModifier()),
                horizontalAlignment = Alignment.CenterHorizontally)
            {
                Div(HeadlineTextStyle.toModifier().fontFamily(Constants.FONTS.POPPINS).margin(bottom = 10.px).color(Colors.White).toAttrs()) {
                    SpanText("The Cherry On Top")
                }


                Div(SubheadlineTextStyle.toModifier().fontFamily(Constants.FONTS.POPPINS).margin(bottom = 2.cssRem).color(Colors.White).toAttrs()) {
                    SpanText("We are so excited to be able to help our growers shine. Please utilize the form below to send us an inquiry so we can get going, and growing, right away!")
                }

                newContactForm(scope)
            }
        }
    }
}