import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.BasePath
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.remove
import com.varabyte.kobweb.silk.defer.DeferringHost
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    AppGlobals.initialize(mapOf("title" to "ICY LEDs"))
    BasePath.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { gr.web.icyleds.pages.HomePage() }
        ctx.router.register("/about") { gr.web.icyleds.pages.AboutUsPage() }
        ctx.router.register("/blog") { gr.web.icyleds.pages.BlogPage() }
        ctx.router.register("/coming-soon") { gr.web.icyleds.pages.ComingSoonPage() }
        ctx.router.register("/privacy-policy") { gr.web.icyleds.pages.PrivacyPolicyPage() }
        ctx.router.register("/products") { gr.web.icyleds.pages.products.ProductsPage() }
        ctx.router.register("/products/fat-pack-1640w") {
                gr.web.icyleds.pages.products.FatPack1640ProductPage() }
        ctx.router.register("/products/fat-pack-820w") {
                gr.web.icyleds.pages.products.FatPack820ProductPage() }
        ctx.router.register("/products/icy-bars") {
                gr.web.icyleds.pages.products.IcyBarsProductPage() }
        ctx.router.register("/products/icy-pop") { gr.web.icyleds.pages.products.IcyPopProductPage()
                }
        ctx.router.register("/terms-of-service") { gr.web.icyleds.pages.TermsOfServicePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", gr.web.icyleds.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", gr.web.icyleds.SubheadlineTextStyle)
        ctx.theme.registerStyle("capped-width", gr.web.icyleds.CappedWidth)
        ctx.theme.registerStyle("justified-text", gr.web.icyleds.justifiedText)
        ctx.theme.registerStyle("product-image-carousel",
                gr.web.icyleds.components.Carousels.ProductImageCarousel)
        ctx.theme.registerStyle("product-image",
                gr.web.icyleds.components.Carousels.ProductImageStyle)
        ctx.theme.registerStyle("product-head",
                gr.web.icyleds.components.Carousels.ProductHeadStyle)
        ctx.theme.registerStyle("product-subhead",
                gr.web.icyleds.components.Carousels.ProductSubheadStyle)
        ctx.theme.registerStyle("product-read-more-button",
                gr.web.icyleds.components.Carousels.ProductReadMoreButtonStyle)
        ctx.theme.registerStyle("product-card-container",
                gr.web.icyleds.components.Carousels.ProductCardContainerStyle)
        ctx.theme.registerStyle("product-gallery-cell",
                gr.web.icyleds.components.Carousels.ProductGalleryCell)
        ctx.theme.registerStyle("product-card",
                gr.web.icyleds.components.Carousels.ProductCardStyle)
        ctx.theme.registerStyle("review-head", gr.web.icyleds.components.Carousels.reviewHeadStyle)
        ctx.theme.registerStyle("review-subhead",
                gr.web.icyleds.components.Carousels.reviewSubheadStyle)
        ctx.theme.registerStyle("review-read-more-button",
                gr.web.icyleds.components.Carousels.reviewReadMoreButtonStyle)
        ctx.theme.registerStyle("review-card-container",
                gr.web.icyleds.components.Carousels.ReviewCardContainerStyle)
        ctx.theme.registerStyle("review-gallery-cell",
                gr.web.icyleds.components.Carousels.reviewGalleryCell)
        ctx.theme.registerStyle("review-card", gr.web.icyleds.components.Carousels.reviewCardStyle)
        ctx.theme.registerStyle("review-picture",
                gr.web.icyleds.components.Carousels.reviewPictureStyle)
        ctx.theme.registerStyle("markdown", gr.web.icyleds.components.layouts.MarkdownStyle)
        ctx.theme.registerStyle("page-content", gr.web.icyleds.components.layouts.PageContentStyle)
        ctx.theme.registerStyle("footer", gr.web.icyleds.components.sections.FooterStyle)
        ctx.theme.registerStyle("footer-link-section",
                gr.web.icyleds.components.sections.FooterLinkSection)
        ctx.theme.registerStyle("footer-link", gr.web.icyleds.components.sections.FooterLinkStyle)
        ctx.theme.registerStyle("footer-column",
                gr.web.icyleds.components.sections.FooterColumnStyle)
        ctx.theme.registerStyle("footer-title", gr.web.icyleds.components.sections.FooterTitleStyle)
        ctx.theme.registerStyle("footer-text", gr.web.icyleds.components.sections.FooterTextStyle)
        ctx.theme.registerStyle("circle-icon", gr.web.icyleds.components.sections.circleIconStyle)
        ctx.theme.registerStyle("feature-title",
                gr.web.icyleds.components.sections.featureTitleStyle)
        ctx.theme.registerStyle("feature-sub-text",
                gr.web.icyleds.components.sections.featureSubTextStyle)
        ctx.theme.registerStyle("feature-column",
                gr.web.icyleds.components.sections.featureColumnStyle)
        ctx.theme.registerStyle("key-features-row",
                gr.web.icyleds.components.sections.KeyFeaturesRowStyle)
        ctx.theme.registerStyle("nav-header", gr.web.icyleds.components.sections.NavHeaderStyle)
        ctx.theme.registerStyle("nav-text", gr.web.icyleds.components.sections.NavTextStyle)
        ctx.theme.registerStyle("nav-subtitle-text",
                gr.web.icyleds.components.sections.NavSubtitleTextStyle)
        ctx.theme.registerStyle("nav-dropdown", gr.web.icyleds.components.sections.NavDropdownStyle)
        ctx.theme.registerStyle("nav-dropdown-content",
                gr.web.icyleds.components.sections.NavDropdownContentStyle)
        ctx.theme.registerStyle("nav-drop-content-transition",
                gr.web.icyleds.components.sections.NavDropContentTransition)
        ctx.theme.registerStyle("nav-dropdown-text",
                gr.web.icyleds.components.sections.NavDropdownTextStyle)
        ctx.theme.registerStyle("nav-dropdown-column-title",
                gr.web.icyleds.components.sections.NavDropdownColumnTitle)
        ctx.theme.registerStyle("nav-bar-contact-us",
                gr.web.icyleds.components.sections.NavBarContactUs)
        ctx.theme.registerStyle("hover-cherry-submit",
                gr.web.icyleds.components.sections.HoverCherrySubmitStyle)
        ctx.theme.registerStyle("hidden-zip-utility-row",
                gr.web.icyleds.components.sections.hiddenZipUtilityRow)
        ctx.theme.registerStyle("scoop-container",
                gr.web.icyleds.components.sections.ScoopContainerStyle)
        ctx.theme.registerStyle("scoop-news-head",
                gr.web.icyleds.components.sections.ScoopNewsHeadStyle)
        ctx.theme.registerStyle("scoop-news-subhead",
                gr.web.icyleds.components.sections.ScoopNewsSubheadStyle)
        ctx.theme.registerStyle("read-more-button",
                gr.web.icyleds.components.sections.ReadMoreButtonStyle)
        ctx.theme.registerStyle("scoop-news-card",
                gr.web.icyleds.components.sections.ScoopNewsCardStyle)
        ctx.theme.registerStyle("contact-form-container",
                gr.web.icyleds.lkpktoolset.ContactForm.contactFormContainer)
        ctx.theme.registerStyle("contact-form-row",
                gr.web.icyleds.lkpktoolset.ContactForm.contactFormRow)
        ctx.theme.registerStyle("contact-row-column",
                gr.web.icyleds.lkpktoolset.ContactForm.contactRowColumn)
        ctx.theme.registerStyle("input-label",
                gr.web.icyleds.lkpktoolset.ContactForm.inputLabelStyle)
        ctx.theme.registerStyle("input", gr.web.icyleds.lkpktoolset.ContactForm.inputStyle)
        ctx.theme.registerStyle("input-focus",
                gr.web.icyleds.lkpktoolset.ContactForm.InputFocusStyle)
        ctx.theme.registerStyle("hey-bear", gr.web.icyleds.lkpktoolset.ContactForm.HeyBear)
        ctx.theme.registerStyle("vert-text-carousel",
                gr.web.icyleds.lkpktoolset.Custom.VertTextCarousel)
        ctx.theme.registerStyle("vert-text-container",
                gr.web.icyleds.lkpktoolset.Custom.VertTextContainer)
        ctx.theme.registerStyle("scrolling-text",
                gr.web.icyleds.lkpktoolset.Custom.scrollingTextStyle)
        ctx.theme.registerStyle("about-us-paragraph", gr.web.icyleds.pages.aboutUsParagraph)
        ctx.theme.registerStyle("about-us-header", gr.web.icyleds.pages.aboutUsHeader)
        ctx.theme.registerStyle("hero-container", gr.web.icyleds.pages.HeroContainerStyle)
        ctx.theme.registerStyle("sub-section-container",
                gr.web.icyleds.pages.SubSectionContainerStyle)
        ctx.theme.registerStyle("carousel-section-columns",
                gr.web.icyleds.pages.carouselSectionColumns)
        ctx.theme.registerStyle("scoop-collective-border",
                gr.web.icyleds.pages.ScoopCollectiveBorder)
        ctx.theme.registerStyle("scoop-collective-plaque",
                gr.web.icyleds.pages.ScoopCollectivePlaqueStyle)
        ctx.theme.registerStyle("shop-now-button", gr.web.icyleds.pages.ShopNowButtonStyle)
        ctx.theme.registerStyle("know-us-button", gr.web.icyleds.pages.KnowUsButtonStyle)
        ctx.theme.registerStyle("learn-more-button", gr.web.icyleds.pages.LearnMoreButtonStyle)
        ctx.theme.registerStyle("header-background", gr.web.icyleds.pages.headerBackground)
        ctx.theme.registerStyle("pink-waffle-background", gr.web.icyleds.pages.pinkWaffleBackground)
        ctx.theme.registerStyle("white-ice-cream-background",
                gr.web.icyleds.pages.whiteIceCreamBackground)
        ctx.theme.registerStyle("the-scoop-bg", gr.web.icyleds.pages.TheScoopBG)
        ctx.theme.registerStyle("product-page-header-background",
                gr.web.icyleds.pages.products.ProductPageHeaderBackground)
        ctx.theme.registerStyle("product-column-button",
                gr.web.icyleds.pages.products.productColumnButtonStyle)
        ctx.theme.registerStyle("video-background-overlay",
                gr.web.icyleds.styles.videoBackgroundOverlay)
        ctx.theme.registerStyle("scroll-img-paragraph-section",
                gr.web.icyleds.styles.ScrollImgParagraphSection)
        ctx.theme.registerStyle("scroll-img-paragraph-title",
                gr.web.icyleds.styles.ScrollImgParagraphTitleStyle)
        ctx.theme.registerStyle("scroll-img-paragraph",
                gr.web.icyleds.styles.ScrollImgParagraphStyle)
        ctx.theme.registerStyle("scroll-img-span", gr.web.icyleds.styles.ScrollImgSpanStyle)
        ctx.theme.registerStyle("scroll-img-container",
                gr.web.icyleds.styles.ScrollImgContainerStyle)
        ctx.theme.registerStyle("scroll-img", gr.web.icyleds.styles.ScrollImgStyle)
        ctx.theme.registerVariant("-circle", gr.web.icyleds.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", gr.web.icyleds.UncoloredButtonVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                gr.web.icyleds.components.sections.SideMenuSlideInAnim)
        ctx.theme.registerKeyframes("animation-zip-utility-row",
                gr.web.icyleds.components.sections.animationZipUtilityRow)
        ctx.theme.registerKeyframes("loading-spinner",
                gr.web.icyleds.lkpktoolset.ContactForm.loadingSpinnerAnimation)
        ctx.theme.registerKeyframes("vert-text-scroll",
                gr.web.icyleds.lkpktoolset.Custom.VertTextScrollAnim)
        gr.web.icyleds.initColorMode(ctx)
        gr.web.icyleds.initSiteStyles(ctx)
        gr.web.icyleds.initTheme(ctx)
    }

    router.tryRoutingTo(BasePath.remove(window.location.href.removePrefix(window.origin)),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        gr.web.icyleds.AppEntry {
            router.renderActivePage { DeferringHost { it() } }
        }
    }
}
