package gr.web.icyleds.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import gr.web.icyleds.SitePalettes
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

val circleIconStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .justifyContent(JustifyContent.Center)
            .alignItems(AlignItems.Center)
            .backgroundColor(SitePalettes.light.brand.pinkAccent)
            .width(8.cssRem)
            .height(8.cssRem)
            .margin(bottom = 1.cssRem)
            .borderRadius(50.percent)
    }
}

val featureTitleStyle = CssStyle {
    base {
        Modifier
            .fontSize(1.6.cssRem)
            .color(Colors.WhiteSmoke)
            .fontWeight(FontWeight.Bolder)
            .textAlign(TextAlign.Center)
            .lineHeight(1.2) //1.5x doesn't look as good on very large text
            .height(3.em)
    }
    Breakpoint.MD {
        Modifier
            .height(2.em)
    }
}

val featureSubTextStyle = CssStyle {
    base {
        Modifier
            .fontSize(1.3.cssRem)
            .textAlign(TextAlign.Center)
            .width(90.percent)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(1.2.cssRem)
            .width(70.percent)
    }
}

val featureColumnStyle = CssStyle {
    base {
        Modifier
            .width(50.percent)
            .textAlign(TextAlign.Center)
            .margin(bottom = 2.em)
    }
    Breakpoint.MD {
        Modifier
            .fillMaxWidth()
            .textAlign(TextAlign.Center)
    }
}

val KeyFeaturesRowStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Row)
            .flexWrap(FlexWrap.Wrap)
            .justifyContent(JustifyContent.Center)
            .margin(bottom = 2.em)
    }
    Breakpoint.MD {
        Modifier
            .flexWrap(FlexWrap.Nowrap)
            .fillMaxWidth()
    }
}

@Composable
fun KeyFeaturesColumns() {
    Div(attrs = KeyFeaturesRowStyle.toAttrs()) {
        Column(featureColumnStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
            Div(circleIconStyle.toAttrs()) {
                Image("icons/renewable-energy.svg",
                    Modifier
                        .width(4.em))
            }
            Div(featureTitleStyle.toAttrs()) {
                SpanText("Energy Efficiency")
            }
            Div(featureSubTextStyle.toModifier().margin(top = 1.cssRem).color(Colors.White).opacity(0.8).toAttrs()) {
                SpanText("Scoop up savings while keeping your plants cool!")
            }
        }
//        Column(featureColumnStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
//            Div(circleIconStyle.toAttrs()) {
//                Image("icons/electromagnetic-spectrum.svg",
//                    Modifier
//                        .width(4.em))
//            }
//            Div(featureTitleStyle.toAttrs()) {
//                SpanText("Superior Spectrum Control")
//            }
//            Div(featureSubTextStyle.toModifier().margin(top = 1.cssRem).color(Colors.White).opacity(0.8).toAttrs()) {
//                SpanText("Serve your plants a rainbow of photon flavors!!")
//            }
//        }
        Column(featureColumnStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
            Div(circleIconStyle.toAttrs()) {
                Image("icons/crops-analytics.svg",
                    Modifier
                        .width(4.em))
            }
            Div(featureTitleStyle.toAttrs()) {
                SpanText("Maximum Yield")
            }
            Div(featureSubTextStyle.toModifier().margin(top = 1.cssRem).color(Colors.White).opacity(0.8).toAttrs()) {
                SpanText("Watch your garden grow faster than ice cream melts on a summer day!")
            }
        }
        Column(featureColumnStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
            Div(circleIconStyle.toAttrs()) {
                Image("icons/longevity.svg",
                    Modifier
                        .width(4.em))
            }
            Div(featureTitleStyle.toAttrs()) {
                SpanText("Durability and Longevity")
            }
            Div(featureSubTextStyle.toModifier().margin(top = 1.cssRem).color(Colors.White).opacity(0.8).toAttrs()) {
                SpanText("Built tough to withstand even the most brain-freezing environments!")
            }
        }
        Column(featureColumnStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
            Div(circleIconStyle.toAttrs()) {
                Image("icons/save-the-world.svg",
                    Modifier
                        .width(4.em))
            }
            Div(featureTitleStyle.toAttrs()) {
                SpanText("Environmentally Friendly")
            }
            Div(featureSubTextStyle.toModifier().margin(top = 1.cssRem).color(Colors.White).opacity(0.8).toAttrs()) {
                SpanText("Feel good about reducing your carbon cone-print!")
            }
        }
    }
}