package gr.web.icyleds.util

import io.ktor.util.*
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(
        hex = "#00A2FF",
        rgb = rgb(r = 0 , g = 162, b = 255)
    ),
    Secondary(
        hex = "#001019",
        rgb = rgb(r = 0, g = 16, b = 25)
    ),
    LightGray(
        hex = "#FAFAFA",
        rgb = rgb(250, 250, 250)
    ),
    HalfWhite(
        hex = "#FFFFF",
        rgb = rgba(255, 255, 255, a = 0.5)
    ),
    White(
        hex = "#FFFFF",
        rgb = rgb(255, 255, 255)
    ),
    HalfBlack(
        hex = "#000000",
        rgb = rgba(r = 0, b = 0, g = 0, a = 0.5)
    ),
    IcyPink(
        hex = "#ffa3e3",
        rgb = rgb(255, 163, 227)
    ),
    IcyBrightPink(
        hex = "#ff8cdc",
        rgb = rgb(255, 140, 220)
    ),
    IcyRed(
        hex = "#ff0000",
        rgb = rgb(255, 0,0)
    ),
    IcyGold(
        hex = "#DECFB9",
        rgb = rgb(222, 178, 96)
    ),
    IcyHalfGold(
        hex = "#f2cb83",
        rgb = rgb(170, 127, 49)
    ),
    IcyTan(
        hex = "#ffe0ab",
        rgb = rgb(255, 224, 171)
    ),
    IcyPurple(
        hex = "",
        rgb = rgb(47, 28, 69)
    ),
    SpectrumRed(
        hex = "#ff4545",
        rgb = rgb(255, 69, 69)
    ),
    SpectrumGreen(
        hex = "#00ff99",
        rgb = rgb(0, 255, 153)
    ),
    SpectrumBlue(
        hex = "#006aff",
        rgb = rgb(0, 106, 255)
    ),
    SpectrumPink(
        hex = "#ff0095",
        rgb = rgb(255, 0, 149)
    )
}