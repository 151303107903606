package gr.web.icyleds.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import gr.web.icyleds.HeadlineTextStyle
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.components.sections.newsCardsRow
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun BlogPage() {

    PageLayout("Blog") {

        Row(HeroContainerStyle.toModifier().then(headerBackground.toModifier()).backgroundColor(Colors.Black), horizontalArrangement = Arrangement.Center) {
            Box() {
                Column(Modifier.gap(1.cssRem), horizontalAlignment = Alignment.CenterHorizontally) {
                    Div(HeadlineTextStyle.toModifier().width(80.percent).toAttrs()) {
                        SpanText("About ICY Leds")
                    }

                    Button(onClick = {}, modifier = ShopNowButtonStyle.toModifier()) {
                        Text("CHECK OUT OUR FEATURES")
                    }
                }
            }
        }

        Row(SubSectionContainerStyle.toModifier().padding(top = 5.vh, bottom = 4.vh).then(whiteIceCreamBackground.toModifier()), horizontalArrangement = Arrangement.Center) {
            Column(Modifier.gap(2.cssRem).fillMaxWidth().margin(leftRight = 2.cssRem), horizontalAlignment = Alignment.CenterHorizontally) {
                Div(HeadlineTextStyle.toModifier().color(Colors.Black).toAttrs()) {
                    SpanText("Sundae Funday: The Scoop on Gardening Goodness")
               }

                Row(Modifier.fillMaxWidth().margin(bottom = 4.cssRem, leftRight = 3.5.cssRem)) {
                    newsCardsRow()
                }
            }
        }

    }

}