package gr.web.icyleds.components.Carousels

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.JustifyContent.Companion.SpaceBetween
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.CSSUrl
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaStar
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import gr.web.icyleds.SitePalettes
import gr.web.icyleds.components.services.fetchReviewData
import gr.web.icyleds.lkpktoolset.Flickity.initializedReviewsFlickity
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Text

data class ReviewCarouselItem(
    val quote: String,
    val desc: String,
    val name: String,
)

val reviewHeadStyle = CssStyle {
    base {
        Modifier
            .fontSize(1.6.cssRem)
            .fontWeight(FontWeight.Bold)
            .textAlign(TextAlign.Start)
            .lineHeight(1.2) //1.5x doesn't look as good on very large text
    }
    Breakpoint.MD {
        Modifier
            .fontSize(1.4.cssRem)
    }
    Breakpoint.LG {
        Modifier
            .fontSize(1.2.cssRem)
    }
}

val reviewSubheadStyle = CssStyle {
    base {
        Modifier
            .flexGrow(1)
            .fontSize(1.cssRem)
            .fontWeight(FontWeight.Light)
            .textAlign(TextAlign.Start)
            .color(Colors.Black)
            .overflow(Overflow.Scroll)
    }
    Breakpoint.MD {
        Modifier
            .fontSize(1.2.cssRem)
    }
    Breakpoint.LG {
        Modifier
            .fontSize(1.cssRem)
    }
}

val reviewReadMoreButtonStyle = CssStyle {
    base {
        Modifier
            .padding(leftRight = 1.8.cssRem)
            .height(3.cssRem)
            .backgroundColor(Colors.Transparent)
            .border(2.px, LineStyle.Solid, Colors.Black)
            .borderRadius(0.px)
            .color(Colors.Black)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))
    }
}

/* Container, card, and gallery cell CSS */

val ReviewCardContainerStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .justifyContent(org.jetbrains.compose.web.css.JustifyContent.SpaceAround)
            .alignItems(org.jetbrains.compose.web.css.AlignItems.Stretch)
            .fillMaxWidth()
            .gap(2.cssRem)
            .padding(leftRight = 2.em)
            .touchAction(TouchAction.None)

    }
}

val reviewGalleryCell = CssStyle {
    //100% height makes them dissappear.
    //No height seems to make Viewport adapt to card size.
    //Specific height size here =/= Card height. Only available space.
    base {
        Modifier
            .classNames("gallery-cell")
            .width(100.percent)
            .margin(bottom = 2.em)
            .height(100.percent)
    }
    Breakpoint.MD {
        Modifier
            .width(75.percent)
    }
    Breakpoint.LG {
        Modifier
            .width(33.percent)
    }
}

val reviewCardStyle = CssStyle {
    //100% Height gives no room for shadow.
    //No height seems to adapt to content! Awesome!
    //Specific height forces card height in available space from gallery cell.
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .justifyContent(justifyContent = SpaceBetween)

            .gap(1.cssRem)
            .backgroundColor(Colors.WhiteSmoke)
            .boxShadow(0.px, 10.px, 3.px, 0.px, rgba(100, 100, 111, 0.4))
            .margin(leftRight = 0.5.em)
            .padding(leftRight = 1.cssRem, topBottom = 2.cssRem)
            .transition(Transition.of("ease-in-out"), Transition.of("all", 0.4.s))

            .minHeight(95.percent)
    }
}

val reviewPictureStyle = CssStyle.base {
    Modifier
        .alignSelf(AlignSelf.Start)
        .display(DisplayStyle.Flex)
        .flexDirection(FlexDirection.Column)
}

@Composable
fun reviewImage(url: CSSUrl) {
    Div(Modifier
        .backgroundImage(url)
        .backgroundPosition(BackgroundPosition.of(CSSPosition.Top))
        .backgroundRepeat(BackgroundRepeat.NoRepeat)
        .backgroundSize(BackgroundSize.of(13.cssRem))
        .width(10.cssRem)
        .height(10.cssRem)
        .borderRadius(50.percent)
        .border(4.px, LineStyle.Solid, SitePalettes.light.brand.lightTanAccent)
        .toAttrs()) {
    }
}

@Composable
fun reviewStars() {
    Div(Modifier.alignSelf(AlignSelf.Center).toAttrs()) {
        for (i in 1..5) {
            FaStar(Modifier
                .color(SitePalettes.light.brand.lightTanAccent)
                .fontSize(1.2.cssRem)
                .margin(top = 10.px)
                .padding(leftRight = 4.px), IconStyle.FILLED)
        }
    }
}

@Composable
fun reviewCard(quote: String, desc: String, name: String, imageurl: CSSUrl) {
    Div(reviewCardStyle.toModifier().toAttrs()) {
        Div(reviewPictureStyle.toAttrs()) {
            reviewImage(imageurl)
            reviewStars()
        }

        Div(reviewHeadStyle.toModifier().color(Colors.Black).toAttrs()) {
            SpanText("\"$quote\"")
        }

        Div(reviewSubheadStyle.toModifier().toAttrs()) {
            SpanText(desc)
        }

        Div(Modifier.fontWeight(FontWeight.Bold).color(Colors.Black).justifySelf(JustifySelf.End).fontSize(1.cssRem).toAttrs()) {
            SpanText("- $name")
        }
    }
}

@Composable
fun flickReviewCarousel(reviews: List<ReviewCarouselItem>) {
    //No one is going to use real face.
    val iceCreamPfpList = listOf("icecream-redwhite.jpg", "icecream-blue.jpg", "icecream-pink.jpg")

    Div(attrs = Modifier
        .classNames("main-carousel", "js-flickity", "flick-rev")
        .fillMaxSize()
        .height(40.em)
        .toAttrs {
            attr("data-flickity", "")
        }) {

        reviews.forEachIndexed() { index, item ->
            Div(attrs = reviewGalleryCell.toModifier().toAttrs()) {
                val profilePic = iceCreamPfpList[index % iceCreamPfpList.size]
                reviewCard(item.quote, item.desc, item.name, url(profilePic))
            }
        }
    }
}

@Composable
fun ReviewCarousel() {
    val scope = rememberCoroutineScope()
    var reviewList by remember { mutableStateOf<List<ReviewCarouselItem>>(listOf()) }
    var fetchedSuccess by remember { mutableStateOf(true) }


    LaunchedEffect(Unit) {
        scope.launch {
            try {
                reviewList = fetchReviewData()
//                console.log("products fetched!")
            } catch (e: Exception) {
                fetchedSuccess = false
                console.error("Error: ${e.message}")
            }
        }
    }

    Div(ReviewCardContainerStyle.toModifier().toAttrs()) {
        if(reviewList.isNotEmpty()) {
            flickReviewCarousel(reviewList)
            initializedReviewsFlickity()
        } else if(!fetchedSuccess) {
            H1() {
                Text("Error retreiving products")
            }
        } else {
            H5() {
                Text("Loading Reviews...")
            }
        }
    }
}