package gr.web.icyleds.components.widgets

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import gr.web.icyleds.util.RES
import org.w3c.dom.Audio

/*
    For fun:
    - Candy Shop - 50 Cent
*/
private val audio = Audio(src = RES.CANDY_SHOP)
private var isPlaying by mutableStateOf(false)
fun candyShop(playing: Boolean) {
    if (isPlaying) {
        audio.pause()
        audio.currentTime = 0.0
    } else {
        audio.play()
    }
    isPlaying = !isPlaying
}
