package gr.web.icyleds.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.clamp
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.VerticalDivider
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.components.widgets.IconButton
import com.varabyte.kobweb.compose.css.AlignItems
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div



/*
        NAVBAR STYLES
*/

val NavHeaderStyle = CssStyle {
    base { Modifier.fillMaxWidth().padding(topBottom = 1.cssRem, leftRight = 1.em) }
    Breakpoint.MD {  Modifier.fillMaxWidth().padding(topBottom = 1.cssRem, leftRight = 5.cssRem) }
}

val NavTextStyle = CssStyle {
    base {
        Modifier
            .fontWeight(FontWeight.Light)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(1.cssRem)
            .cursor(Cursor.Pointer)
    }
    cssRule(":hover") {
        Modifier
            .color(Theme.IcyPink.rgb)
            .transition(Transition.of(TransitionProperty.All, 250.ms))
    }
}

val NavSubtitleTextStyle = CssStyle {
    base {
        Modifier
            .fontWeight(FontWeight.Light)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(.8.cssRem)
            .color(Theme.LightGray.rgb)
    }
}



/*
        NAVBAR DROPDOWN ITEM STYLES + TRANSITIONS + HOVERS
*/

val NavDropdownStyle = CssStyle {
    base {
        Modifier
            .position(Position.Relative)
            //.float(CSSFloat.Left) //Old
            //.overflow(Overflow.Hidden) //Old
    }
    cssRule(":hover > .dropdown-content") {
        Modifier
            .visibility(Visibility.Visible)
    }
    cssRule(":hover > .dropdown-content > .dropdown-transition") {
        Modifier
            .opacity(100)
            .translateY(0.px)
    }
    cssRule(":not(:hover) > .dropdown-content > .dropdown-transition") {
        Modifier
            .opacity(0)
            .translateY(10.px)
            .transition(Transition.None)
    }
    cssRule(":hover > a > .nav-text") {
        Modifier
            .color(Theme.IcyPink.rgb)
    }
}

val NavDropdownContentStyle = CssStyle {
    base {
        Modifier
            .visibility(Visibility.Hidden)
            .position(Position.Absolute)
            .backgroundColor(Colors.Black)
            .minWidth(200.px)
            .zIndex(9)
            //.margin(left = (-30).px) //Old

            .left(50.percent)
            .translateX((-50).percent)
            .top(100.percent)

            .padding(10.px)
            .borderRadius(10.px)
    }
    cssRule(":hover") {
        Modifier
            .visibility(Visibility.Visible)
    }
}

val NavDropContentTransition = CssStyle {
    base {
        Modifier
            .opacity(0)
            .translateY(10.px)
            .transition(Transition.of(TransitionProperty.All, 500.ms))
    }
}

val NavDropdownTextStyle = CssStyle {
    base {
        Modifier

    }
}

val NavDropdownColumnTitle = CssStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(.9.cssRem)
            .fontWeight(FontWeight.Bold)
            .margin(bottom = 5.px)
    }
}

val NavBarContactUs = CssStyle {
    base {
        Modifier
            .textDecorationLine(TextDecorationLine.None)
            .padding(4.px)
            .border(1.px, LineStyle.Solid, Theme.IcyPink.rgb)
            .borderRadius(4.px)
            .color(Theme.IcyPink.rgb)
            .fontWeight(FontWeight.Light)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(1.cssRem)
            .cursor(Cursor.Pointer)
            .transition(Transition.all(250.ms))
    }
    hover {
        Modifier
            .backgroundColor(Theme.IcyPink.rgb)
            .color(Colors.White)
    }
}

/*
        NAVBAR COMPOSABLE TO USE
*/

@Composable
fun NavLink(toLink: String, text: String) {
    Link(toLink, Modifier
        .textDecorationLine(TextDecorationLine.None)
        .color(Colors.White)) {
        SpanText(text, NavTextStyle.toModifier())
    }
}

@Composable
fun NavLinkSubtitle(toLink: String, text: String, subtitle: String) {
    Link(toLink, Modifier
        .textDecorationLine(TextDecorationLine.None)
        .color(Colors.White)) {

        SpanText(text, NavTextStyle.toModifier())
        SpanText(subtitle, NavSubtitleTextStyle.toModifier())
    }
}

@Composable
fun NavDropdown(breakpoint: Breakpoint, toLink: String, text: String, content: @Composable () -> Unit) {

    Div(NavDropdownStyle.toAttrs()) {
        NavLink(toLink, text)

        if (breakpoint > Breakpoint.SM) {
            Div(NavDropdownContentStyle.toModifier()
                .classNames("dropdown-content")
                .toAttrs()
            ) {
                Div(NavDropContentTransition.toModifier().classNames("dropdown-transition").toAttrs()) {
                    content()
                }
            }
        }

    }
}

@Composable
private fun MenuItems(breakpoint: Breakpoint) {
    NavLink(ROUTES.HOME, "HOME")
    NavDropdown(breakpoint, ROUTES.OUR_SCOOP, "OUR SCOOP") {
        Column(Modifier.gap(10.px), horizontalAlignment = Alignment.CenterHorizontally) {
            NavLink(ROUTES.OUR_SCOOP, "About Us")
//            NavLink(ROUTES.COMING_SOON, "Meet The Team")
//            NavLink(ROUTES.COMING_SOON, "Meet The Customers")
//            NavLink(ROUTES.COMING_SOON, "See Our Stats")
//            NavLink(ROUTES.COMING_SOON, "Why Icy")
        }
    }
    NavDropdown(breakpoint, ROUTES.OUR_FLAVORS, "OUR FLAVORS") {
        Row(Modifier.display(DisplayStyle.Flex).alignItems(AlignItems.Stretch).width(Width.MaxContent).whiteSpace(WhiteSpace.NoWrap), verticalAlignment = Alignment.Top) {
            Column(Modifier.gap(10.px).margin(leftRight = 10.px)) {
                SpanText("Under Canopy", NavDropdownColumnTitle.toModifier())
                NavLinkSubtitle(ROUTES.PRODUCTS.ICY_POP, "ICY POP", " 120W")
            }
            Column(Modifier.gap(10.px).margin(leftRight = 10.px)) {
                SpanText("Top Light", NavDropdownColumnTitle.toModifier())
                NavLinkSubtitle(ROUTES.PRODUCTS.ICY_BARS, "ICY BARS", " 820W")
                NavLinkSubtitle(ROUTES.PRODUCTS.FAT_PACK820, "FAT PACK", " 820W")
                NavLinkSubtitle(ROUTES.PRODUCTS.FAT_PACK1640, "FAT PACK", " 1640W")

            }
            Column(Modifier.gap(10.px).borderLeft(1.px, LineStyle.Solid, Theme.LightGray.rgb).padding(leftRight = 10.px)) {
                SpanText("Other", NavDropdownColumnTitle.toModifier())
                NavLink("${ROUTES.OUR_FLAVORS}/#contact-us", "Our Pricing")
                NavLink("${ROUTES.OUR_FLAVORS}/#eligibility", "Your Rebate Eligibility")
            }
//            Column(Modifier.gap(10.px).width(125.px)) {
//                SpanText("Green House", NavDropdownColumnTitle.toModifier())
//                SpanText("Coming Soon", NavTextStyle.toModifier().color(Theme.LightGray.rgb).fontStyle(FontStyle.Italic))
//            }
        }
    }

    Link(
        path = ROUTES.SECTIONS.HOME_CONTACT,
        modifier =  NavBarContactUs.toModifier()
    ) {
        SpanText("CONTACT US")
    }

//    NavLink(ROUTES.SECTIONS.HOME_CONTACT, "CONTACT US") //TODO make more of a button?
}


@Composable
private fun HamburgerButton(onClick: () -> Unit) {
    IconButton(onClick) {
        HamburgerIcon(Modifier.color(Colors.White))
    }
}

@Composable
private fun CloseButton(onClick: () -> Unit) {
    IconButton(onClick) {
        CloseIcon(Modifier.color(Colors.White))
    }
}

val SideMenuSlideInAnim = Keyframes {
    from {
        Modifier.translateX(100.percent)
    }

    to {
        Modifier
    }
}

// Note: When the user closes the side menu, we don't immediately stop rendering it (at which point it would disappear
// abruptly). Instead, we start animating it out and only stop rendering it when the animation is complete.
enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun NavHeader() {
    val breakpoint = rememberBreakpoint()


    Row(NavHeaderStyle.toModifier().backgroundColor(Colors.Black), verticalAlignment = Alignment.CenterVertically) {
        Link(ROUTES.HOME) {
            // Block display overrides inline display of the <img> tag, so it calculates centering better
            Image(RES.ICY_LOGO, "ICY Logo", Modifier.height(6.cssRem).display(DisplayStyle.Block))
        }

        Spacer()

        Row(Modifier.gap(1.5.cssRem).displayIfAtLeast(Breakpoint.LG), verticalAlignment = Alignment.CenterVertically) {
            MenuItems(breakpoint)
        }

        Row(
            Modifier
                .fontSize(1.5.cssRem)
                .gap(1.cssRem)
                .displayUntil(Breakpoint.LG),
            verticalAlignment = Alignment.CenterVertically
        ) {
            var menuState by remember { mutableStateOf(SideMenuState.CLOSED) }

            HamburgerButton(onClick =  { menuState = SideMenuState.OPEN })

            if (menuState != SideMenuState.CLOSED) {
                SideMenu(
                    breakpoint,
                    menuState,
                    close = { menuState = menuState.close() },
                    onAnimationEnd = { if (menuState == SideMenuState.CLOSING) menuState = SideMenuState.CLOSED }
                )
            }
        }
    }
}

@Composable
private fun SideMenu( breakpoint: Breakpoint, menuState: SideMenuState, close: () -> Unit, onAnimationEnd: () -> Unit) {
    Overlay(
        Modifier
            .zIndex(10)
            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { close() }
    ) {
        key(menuState) { // Force recompute animation parameters when close button is clicked
            Column(
                Modifier
                    .fillMaxHeight()
                    .width(clamp(10.cssRem, 33.percent, 10.cssRem))
                    .align(Alignment.CenterEnd)
                    .padding(top = 1.cssRem, leftRight = 1.cssRem)
                    .gap(1.5.cssRem)
                    .backgroundColor(Colors.Black)
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .borderRadius(topLeft = 2.cssRem)
                    .onClick { it.stopPropagation() }
                    .onAnimationEnd { onAnimationEnd() },
                horizontalAlignment = Alignment.End
            ) {
                CloseButton(onClick = { close() })
                Column(Modifier.padding(right = 0.75.cssRem).gap(1.5.cssRem).fontSize(1.4.cssRem), horizontalAlignment = Alignment.End) {
                    MenuItems(breakpoint)
                }
            }
        }
    }
}
