package gr.web.icyleds.pages.products

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.CappedWidth
import gr.web.icyleds.HeadlineTextStyle
import gr.web.icyleds.SubheadlineTextStyle
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.components.sections.newContactForm
import gr.web.icyleds.pages.SubSectionContainerStyle
import gr.web.icyleds.pages.pinkWaffleBackground
import gr.web.icyleds.pages.whiteIceCreamBackground
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

val ProductPageHeaderBackground = CssStyle {
    base {
        Modifier
            .background(
                Background.of(
                    image = BackgroundImage.of(
                        url(RES.ICE_CREAM_BG)
                    ),
                    repeat = BackgroundRepeat.NoRepeat,
                    position = BackgroundPosition.of(CSSPosition.Bottom),
                    size = BackgroundSize.Cover,
                ),
                Background.of(
                    image = linearGradient(LinearGradient.Direction.ToTop) {
                        add(Color.rgba(255, 255, 255, 0.15f), 60.percent)
                        add(Color.rgb(0x000000), 100.percent)
                    }.toImage()
                ),
                Background.of(
                    image = linearGradient(LinearGradient.Direction.ToTop) {
                        add(rgba(0,0,0, 0.6), 100.percent)
                        add(Colors.Black, 100.percent)
                    }.toImage()
                )
            )
    }
}

data class ProductsPageUiState(
    val selectedColumn: Int = 0,
    val productColumns: List<String> = listOf("icy pop", "icy bar", "fat pack")
)

@Page(routeOverride = "/products")
@Composable
fun ProductsPage() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()
    val context = rememberPageContext()
    var uiState by remember { mutableStateOf(ProductsPageUiState()) }


    PageLayout("Products") {

        Column(
            modifier = Modifier
                .id("pricing")
                .then(ProductPageHeaderBackground.toModifier())
                .fillMaxWidth()
                .padding(bottom = 2.cssRem)
                .backgroundColor(Theme.IcyGold.rgb),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            //Pricing
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        top = if (breakpoint < Breakpoint.MD) 2.cssRem else 2.cssRem,
                        bottom = if (breakpoint < Breakpoint.MD) 1.cssRem else 2.cssRem
                    ),
                horizontalArrangement = Arrangement.Center
            ) {
                Column(
                    modifier = Modifier
                        .gap(if (breakpoint < Breakpoint.MD) 0.cssRem else 1.cssRem)
                        .then(CappedWidth.toModifier()),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Div(
                        HeadlineTextStyle.toModifier()
                            .margin(bottom = 8.px)
                            .fontFamily(Constants.FONTS.MACHINARY)
                            .fontWeight(FontWeight.Normal)
                            .color(Theme.IcyGold.rgb)
                            .fontSize(if (breakpoint < Breakpoint.MD) 58.px else 78.px)
                            .letterSpacing(0.px)
                            .toAttrs()
                    ) {
                        SpanText("Our Flavors")
                    }

                    Div(
                        SubheadlineTextStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .fontStyle(FontStyle.Italic)
                            .fontWeight(FontWeight.Medium)
                            .toAttrs()
                    ) {
                        SpanText(
                            text = "Satisfy Your Sweet Tooth",
                            modifier = Modifier
                                .fontSize(24.px)
                                .display(DisplayStyle.InlineBlock)
                        )
                    }
                }
            }

            Row(
                modifier = Modifier
                    .displayUntil(Breakpoint.MD)
                    .width(100.percent - 1.cssRem),
                horizontalArrangement = Arrangement.Center
            ) {
                ColumnSelector(
                    breakpoint,
                    selectedColumn = uiState.selectedColumn,
                    columnNames = uiState.productColumns
                ) { columnIndexSelect ->
                    uiState = uiState.copy(selectedColumn = columnIndexSelect)
                }
            }

            Row(
                modifier = Modifier
                    .displayUntil(Breakpoint.MD)
                    .width(100.percent - 1.cssRem),
                horizontalArrangement = Arrangement.Center
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .position(Position.Relative)
                        .padding(20.px),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    when(uiState.selectedColumn) {
                        0 -> IcyPopFlavorColumn(context, breakpoint)
                        1 -> IcyBarFlavorColumn(context, breakpoint)
                        2 -> FatPackFlavorColumn(context, breakpoint)
                    }
                }
            }


            Row(
                modifier = Modifier
                    .displayIfAtLeast(Breakpoint.MD)
                    .backgroundColor(Theme.HalfBlack.rgb)
                    .padding(20.px)
                    .borderRadius(10.px)
                    .width(min(1200.px, 100.percent - 1.cssRem)),
                horizontalArrangement = Arrangement.Center
            ) {

                //Column Flavor 1
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .position(Position.Relative)
                        .padding(20.px),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    IcyPopFlavorColumn(context, breakpoint)
                }

                //Column Flavor 2
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .position(Position.Relative)
                        .padding(20.px)
                        .borderLeft(1.px, LineStyle.Solid, Theme.HalfWhite.rgb)
                        .borderRight(1.px, LineStyle.Solid, Theme.HalfWhite.rgb),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    IcyBarFlavorColumn(context, breakpoint)
                }

                //Column Flavor 3
                Column(
                    modifier = Modifier
                        .fillMaxSize()
                        .position(Position.Relative)
                        .padding(20.px),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    FatPackFlavorColumn(context, breakpoint)
                }
            }

            Div(
                attrs = Modifier
                    .width(370.px)
                    .margin(leftRight = autoLength)
                    .toAttrs(),
            ) {
                H3(
                    attrs = Modifier
                        .margin(top = 1.cssRem, bottom = 0.cssRem)
                        .fontSize(20.px)
                        .textAlign(TextAlign.Center)
                        .color(Colors.LightGray)
                        .toAttrs()
                ) {
                    Text("ALL OF OUR PRODUCTS ARE")
                }

                Ul(
                    attrs = Modifier
                        .fontSize(16.px)
                        .margin(0.px)
                        .fontWeight(FontWeight.Light)
                        .fontStyle(FontStyle.Italic)
                        .color(Colors.LightGray)
                        .toAttrs()
                ) {
                    Li(
                        attrs = Modifier
                            .toAttrs()
                    ) {
                        Text("Designed Specifically for rebate eligibility")
                    }

                    Li(
                        attrs = Modifier
                            .toAttrs()
                    ) {
                        Text("DLC Listed")
                    }
                }

            }
        }

        Row(
            modifier = Modifier
                .id("eligibility")
                .then(whiteIceCreamBackground.toModifier())
                .padding(topBottom = 3.cssRem)
                .fillMaxWidth(),
            horizontalArrangement = Arrangement.Center
        ) {

            Column(
                modifier = CappedWidth.toModifier(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                H2(
                    attrs = Modifier
                        .margin(topBottom = 1.cssRem)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .fontSize(if (breakpoint < Breakpoint.MD) 32.px else 48.px)
                        .toAttrs()
                ) { Text("Rebate Eligibility") }


                P(
                    attrs = Modifier
                        .fontWeight(FontWeight.Light)
                        .padding(0.px)
                        .margin(bottom = 12.px)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .fontSize(24.px)
                        .toAttrs()
                ) {
                    Text("Rebates are our most popular topping! In fact, one of the biggest factors in Icy LED becoming a reality was the benefit of Rebates and how dramatically they can reduce the impact of equipment purchases on the ‘ol bank account.")
                }

                P(
                    attrs = Modifier
                        .fontWeight(FontWeight.Light)
                        .padding(0.px)
                        .margin(bottom = 12.px)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .fontSize(24.px)
                        .toAttrs()
                ) {
                    Text("We work with the sweetest and most elite Rebate service providers in the industry. A complimentary rebate assessment will be conducted with every Icy purchase to ensure that you don't miss out on a single dollar that can help you pay for lights.")
                }

                P(
                    attrs = Modifier
                        .fontWeight(FontWeight.Light)
                        .padding(0.px)
                        .margin(0.px)
                        .fontFamily(Constants.FONTS.POPPINS)
                        .fontSize(24.px)
                        .toAttrs()
                ) {
                    Text("Fill out the form below and we will provide a saucy rebate estimate to show you how much you could be saving today.")
                }



            }
        }

        Row(
            SubSectionContainerStyle.toModifier()
            .id("contact-us")
            .then(pinkWaffleBackground.toModifier()),
            horizontalArrangement = Arrangement.Center)
        {
            Column(Modifier
                .then(CappedWidth.toModifier()),
                horizontalAlignment = Alignment.CenterHorizontally)
            {
                Div(HeadlineTextStyle.toModifier().fontFamily(Constants.FONTS.POPPINS).margin(bottom = 10.px).color(Colors.White).toAttrs()) {
                    SpanText("The Cherry On Top")
                }


                Div(SubheadlineTextStyle.toModifier().fontFamily(Constants.FONTS.POPPINS).margin(bottom = 4.cssRem).color(Colors.White).toAttrs()) {
                    SpanText("We are so excited to be able to help our growers shine. Please utilize the form below to send us an inquiry so we can get going, and growing, right away!")
                }

                newContactForm(scope)
            }
        }


        //Not Being used Products Carousel
//        Row(
//            SubSectionContainerStyle.toModifier()
//            .padding(top = 5.cssRem, bottom = 5.cssRem)
//            .backgroundColor(Theme.IcyTan.rgb),
//            horizontalArrangement = Arrangement.Center
//        ) {
//            Column(CappedWidth.toModifier(),
//                horizontalAlignment = Alignment.CenterHorizontally
//            ) {
//                Div(HeadlineTextStyle.toModifier().color(Colors.Black).toAttrs()) {
//                    SpanText("Our Products: Scoops of LED Delight")
//                }
//
//                ProductCarousel()
//            }
//        }

    }
}


/*
       FLAVOR COLUMNS
       - Different per flavor
       - Should follow similar style, but per light things may need to be different.
       - Inside Composable here, so that it can be used in both Desktop and Mobile view for the page.
*/

@Composable
fun ColumnSelector(
    breakpoint: Breakpoint,
    columnNames: List<String>,
    selectedColumn: Int,
    onSelect: (Int) -> Unit,
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .margin(topBottom = 1.cssRem),
        horizontalArrangement = Arrangement.SpaceEvenly,
        verticalAlignment = Alignment.CenterVertically
    ) {
        columnNames.forEachIndexed() { index, name ->
            Div(
                attrs = Modifier
                    .textOverflow(TextOverflow.Ellipsis)
                    .whiteSpace(WhiteSpace.NoWrap)
                    .onClick {
                        onSelect(index)
                    }
                    .toAttrs()
            ) {
                SpanText(
                    text = name.uppercase(),
                    modifier = Modifier
                        .fontWeight(FontWeight.Bold)
                        .fontSize(20.px)
                        .textAlign(TextAlign.Center)
                        .padding(
                            leftRight = 5.px,
                            bottom = 5.px
                        )
                        .color(if (selectedColumn == index) Colors.White else Theme.IcyPink.rgb)
                        .borderBottom(
                            width = 10.px,
                            style = LineStyle.Solid,
                            color = if (selectedColumn == index) Colors.White else Theme.IcyPink.rgb
                        )
                        .transition(Transition.all(500.ms))
                )
            }
        }
    }
}

@Composable
fun IcyPopFlavorColumn(context: PageContext, breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(300.px)
            .gap(50.px),
        contentAlignment = Alignment.Center
    ) {
        Img(
            src = RES.ICY_POP3,
            alt = "ICY POP Product Image",
            attrs = Modifier
                .objectFit(ObjectFit.Contain)
                .height(autoLength)
                .fillMaxWidth()
                .toAttrs()
        )
    }

    Img(
        src = RES.ICY_POP_LOGO,
        alt = "ICY POP Logo",
        attrs = Modifier
            .fillMaxWidth()
            .height(autoLength)
            .objectFit(ObjectFit.ScaleDown)
            .margin(topBottom = 1.cssRem)
            .toAttrs()
    )

    Ul(
        attrs = Modifier
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(21.px)
            .margin(topBottom = 0.cssRem)
            .color(Colors.White)
            .fillMaxWidth()
            .toAttrs()
        ) {
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Increase Yields by up to 30%")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Provide consistent light coverage")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Save time with easy setup and installation")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Grow efficiently with 3.0 µmol/J efficacy")
        }
    }

    P(
        attrs = Modifier
            .fillMaxWidth()
            .textAlign(TextAlign.Start)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(24.px)
            .fontWeight(FontWeight.Light)
            .color(Colors.White)
            .toAttrs()
    ) {
        Text("Icy POP UNDER CANOPY LED Grow Lights are designed to provide your plants with the light they need to produce higher quality and quantity of yields.")
    }

    Button(
        attrs = Modifier
            .position(if (breakpoint < Breakpoint.MD) Position.Relative else Position.Absolute)
            .margin(top = 12.px)
            .bottom(0.px)
            .then(productColumnButtonStyle.toModifier())
            .onClick {
                context.router.navigateTo(ROUTES.PRODUCTS.ICY_POP)
            }
            .toAttrs()
    ) {
        Text("SEE MORE")
    }
}

@Composable
fun IcyBarFlavorColumn(context: PageContext, breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(300.px)
            .gap(50.px),
        contentAlignment = Alignment.Center
    ) {
        Img(
            src = RES.ICY_BARS1,
            alt = "ICY POP Product Image",
            attrs = Modifier
                .objectFit(ObjectFit.Contain)
                .height(autoLength)
                .fillMaxWidth()
                .toAttrs()
        )
    }

    Img(
        src = RES.ICY_BARS_LOGO,
        alt = "ICY BARS Logo",
        attrs = Modifier
            .width(autoLength)
            .height(140.px)
            .objectFit(ObjectFit.ScaleDown)
            .margin(bottom = 1.cssRem)
            .toAttrs()
    )

    Ul(
        attrs = Modifier
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(18.px)
            .margin(bottom = 0.cssRem)
            .color(Colors.White)
            .fillMaxWidth()
            .toAttrs()
    ) {
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Full-Cycle, Full-Spectrum light output")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Ideal for Indoor Only Facilities")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Commercial Grade, all aluminum housing for maximum heat dissipation")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Dual RJ12 Ports Compatible with 0-10V room control systems")
        }
    }

    P(
        attrs = Modifier
            .fillMaxWidth()
            .textAlign(TextAlign.Start)
            .padding(bottom = 4.cssRem)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(24.px)
            .fontWeight(FontWeight.Light)
            .color(Colors.White)
            .toAttrs()
    ) {
        Text("Icy BARS LED Grow Lights are robust and full-spectrum, designed to support plants throughout their entire growth cycle, from the vegetative phase to the light-intensive bloom and finishing stages.")
    }

    Button(
        attrs = Modifier
            .position(if (breakpoint < Breakpoint.MD) Position.Relative else Position.Absolute)
            .margin(top = 12.px)
            .bottom(0.px)
            .then(productColumnButtonStyle.toModifier())
            .onClick {
                context.router.navigateTo(ROUTES.PRODUCTS.ICY_BARS)
            }
            .toAttrs()
    ) {
        Text("SEE MORE")
    }
}

@Composable
fun FatPackFlavorColumn(context: PageContext, breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(300.px)
            .gap(50.px),
        contentAlignment = Alignment.Center
    ) {
        Img(
            src = RES.FAT_PACK16_1,
            alt = "ICY POP Product Image",
            attrs = Modifier
                .objectFit(ObjectFit.Contain)
                .height(autoLength)
                .fillMaxWidth()
                .toAttrs()
        )
    }

    Img(
        src = RES.FAT_PACK_LOGO,
        alt = "ICY BARS Logo",
        attrs = Modifier
            .fillMaxWidth()
            .height(autoLength)
            .objectFit(ObjectFit.ScaleDown)
            .margin(topBottom = 1.cssRem)
            .toAttrs()
    )

    Ul(
        attrs = Modifier
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(18.px)
            .margin(topBottom = 0.cssRem)
            .color(Colors.White)
            .fillMaxWidth()
            .toAttrs()
    ) {
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Full-Cycle, Full-Spectrum light output")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Light weight, Low Profile design")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Commercial Grade, all aluminum housing for maximum heat dissipation")
        }
        Li(
            attrs = Modifier
                .fontWeight(FontWeight.Light)
                .toAttrs()
        ) {
            SpanText("Dual RJ12 Ports Compatible with 0-10V room control systems")
        }
    }

    P(
        attrs = Modifier
            .fillMaxWidth()
            .textAlign(TextAlign.Start)
            .fontFamily(Constants.FONTS.POPPINS)
            .fontSize(24.px)
            .fontWeight(FontWeight.Light)
            .color(Colors.White)
            .toAttrs()
    ) {
        Text("Icy FAT PACK LED Grow Lights are full-cycle, high-preforming, top-light solutions for indoor and greenhouse commercial cultivation.")
    }

    Row(
        modifier = Modifier
            .position(if (breakpoint < Breakpoint.MD) Position.Relative else Position.Absolute)
            .margin(top = 12.px)
            .bottom(0.px)
            .fillMaxWidth(),
        horizontalArrangement = Arrangement.Center
    ) {
        Button(
            attrs = Modifier
                .then(productColumnButtonStyle.toModifier())
                .width(125.px)
                .margin(right = 8.px)
                .onClick {
                    context.router.navigateTo(ROUTES.PRODUCTS.FAT_PACK820)
                }
                .toAttrs()
        ) {
            Text("820W")
        }

        Button(
            attrs = Modifier
                .then(productColumnButtonStyle.toModifier())
                .width(125.px)
                .onClick {
                    context.router.navigateTo(ROUTES.PRODUCTS.FAT_PACK1640)
                }
                .toAttrs()
        ) {
            Text("1640W")
        }
    }
}

val productColumnButtonStyle = CssStyle {
    base {
        Modifier
            .width(200.px)
            .height(54.px)
            .fontSize(20.px)
            .fontWeight(FontWeight.Bold)
            .backgroundColor(Colors.Transparent)
            .border(
                width = 3.px,
                style = LineStyle.Solid,
                color = Theme.IcyBrightPink.rgb
            )
            .borderRadius(4.px)
            .cursor(Cursor.Pointer)
            .color(Theme.IcyPink.rgb)
    }
    hover {
        Modifier
            .transition(Transition.all(250.ms))
            .backgroundColor(Theme.IcyPink.rgb)
            .color(Colors.White)
            .scale(0.95)
    }
}