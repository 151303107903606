package gr.web.icyleds.pages.products

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.defer.Deferred
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.CappedWidth
import gr.web.icyleds.components.Carousels.loadProductImageFlickity
import gr.web.icyleds.components.Carousels.ProductImageCarousel
import gr.web.icyleds.components.PicturePopup
import gr.web.icyleds.components.ProductUIEvents
import gr.web.icyleds.components.layouts.PageLayout
import gr.web.icyleds.styles.VideoBackground
import gr.web.icyleds.util.Constants
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

@Page
@Composable
fun IcyPopProductPage() {
    val breakpoint = rememberBreakpoint()
    val context = rememberPageContext()
    var uiState by remember { mutableStateOf(ProductUIEvents()) }
    val imageList = listOf(RES.ICY_POP3, RES.ICY_POP4, RES.ICY_POP5, RES.ICY_POP1, RES.ICY_POP2)

    LaunchedEffect(Unit) {
        loadProductImageFlickity()
    }

    Deferred {

    }

    PageLayout("ICY POP") {
        VideoBackground(
            modifier = Modifier
                .fillMaxSize()
                .position(Position.Relative)
                .overflow(Overflow.Hidden)
        ) {

            Row(
                modifier = Modifier
                    .padding(top = 1.cssRem, bottom = 3.cssRem)
                    .zIndex(1),
                horizontalArrangement = Arrangement.Center)
            {
                Column(
                    modifier = Modifier
                        .padding(top = 0.cssRem, bottom = 6.cssRem)
                        .then(CappedWidth.toModifier()),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {

                    /*
                            Products Image Carousel
                     */
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center,
                    ) {
                        ProductImageCarousel(imageList)
                    }

                    /*
                            Product Information
                     */
                    SimpleGrid(
                        numColumns = numColumns(base = 1, sm = 2),
                        modifier = Modifier
                            .padding(top = 5.cssRem)
                            .fillMaxWidth()
                    ) {
                        //Column 1
                        Column(
                            modifier = Modifier
                                .padding(all = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.px)
                                .fillMaxWidth()
                                .textAlign(TextAlign.Center),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {

                            Img(
                                src = RES.ICY_POP_LOGO,
                                alt = "ICY POP Logo",
                                attrs = Modifier
                                    .width(if (breakpoint < Breakpoint.MD) 100.percent else 80.percent )
                                    .height(autoLength)
                                    .objectFit(ObjectFit.ScaleDown)
                                    .toAttrs()
                            )

                            H3(
                                attrs = Modifier
                                    .margin(top = 1.cssRem)
                                    .fontSize(20.px)
                                    .textAlign(TextAlign.Center)
                                    .color(Colors.White)
                                    .toAttrs()
                            ) {
                                Text("ICY POP 120W UNDER CANOPY LED GROW LIGHT")
                            }

                            Ul(
                                attrs = Modifier
                                    .fontFamily(Constants.FONTS.POPPINS)
                                    .fontSize(24.px)
                                    .listStyle(ListStyleType.Square)
                                    .textAlign(TextAlign.Start)
                                    .fillMaxWidth()
                                    .margin(topBottom = 2.cssRem)
                                    .color(Colors.White)
                                    .gap(2.cssRem)
                                    .toAttrs()
                            ) {
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Increase yields by up to 30%")
                                }
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Provide consistent light coverage")
                                }
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Save time with easy setup and installation")
                                }
                                Li(
                                    attrs = Modifier
                                        .fontWeight(FontWeight.Light)
                                        .toAttrs()
                                ) {
                                    SpanText("Grow efficiently with 3.0 µmol/J efficacy")
                                }
                            }

                            HorizontalDivider(Modifier.color(Theme.HalfBlack.rgb))

                            P(
                                attrs = Modifier
                                    .fontSize(18.px)
                                    .fontFamily(Constants.FONTS.POPPINS)
                                    .fontWeight(FontWeight.Normal)
                                    .color(Colors.White)
                                    .textAlign(TextAlign.Justify)
                                    .margin(topBottom = 15.px)
                                    .toAttrs()
                            ) {
                                Text("Icy POP UNDER CANOPY LED Grow Lights are designed to provide your plants with the light they need to produce higher quality and quantity of yields.")
                            }

                            P(
                                attrs = Modifier
                                    .fontSize(18.px)
                                    .fontFamily(Constants.FONTS.POPPINS)
                                    .fontWeight(FontWeight.Normal)
                                    .color(Colors.White)
                                    .textAlign(TextAlign.Start)
                                    .margin(0.px)
                                    .toAttrs()
                            ) {
                                Text("Icy POP UNDER CANOPY LED Grow Lights are specifically designed to sit below the plant canopy, bringing light deep into your vegetation to increase flowering sites. Icy’s under-canopy lights are an excellent way to enhance your grow. Designed to complement overhead lighting systems, this solution provides a low-maintenance, low-cost way to increase yields.")
                            }

                        }

                        //Column 2
                        Column(
                            modifier = Modifier
                                .padding(all = if (breakpoint < Breakpoint.MD) 1.cssRem else 0.px)
                                .fillMaxWidth(),
                            horizontalAlignment = if (breakpoint < Breakpoint.MD) Alignment.CenterHorizontally else Alignment.End,
                            verticalArrangement = Arrangement.Top
                        ) {
                            Img(
                                src = RES.ICY_POP_FACTS,
                                alt = "ICY POP facts",
                                attrs = Modifier
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        uiState = uiState.copy(imgPopup = true, imgURL = RES.ICY_POP_FACTS)
                                    }
                                    .width(if (breakpoint < Breakpoint.MD) 100.percent else 80.percent )
                                    .height(autoLength)
                                    .objectFit(ObjectFit.Contain)
                                    .margin(bottom = 1.cssRem)
                                    .toAttrs()
                            )

                            Img(
                                src = RES.ICY_POP_SPECTRUM,
                                alt = "ICY POP spectrum",
                                attrs = Modifier
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        uiState = uiState.copy(imgPopup = true, imgURL = RES.ICY_POP_SPECTRUM)
                                    }
                                    .width(if (breakpoint < Breakpoint.MD) 100.percent else 85.percent )
                                    .height(autoLength)
                                    .objectFit(ObjectFit.Contain)
                                    .toAttrs()
                            )
                        }
                    }



                    Column(
                        modifier = Modifier
                            .displayIfAtLeast(Breakpoint.MD)
                            .padding(top = 3.cssRem)
                            .fillMaxWidth(),
                        verticalArrangement = Arrangement.Center,
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {

                        SpanText(
                            text = "Dont let the hype melt!",
                            modifier = Modifier
                                .fillMaxWidth()
                                .textAlign(TextAlign.Center)
                                .margin(bottom = 1.cssRem)
                                .fontFamily(Constants.FONTS.BATUPHAT)
                                .letterSpacing(1.px)
                                .fontSize(45.px)
                                .color(Theme.IcyPink.rgb)
                        )

                        Button(
                            attrs = Modifier
                                .then(productColumnButtonStyle.toModifier())
                                .onClick {
                                    context.router.navigateTo(ROUTES.SECTIONS.PRODUCTS_CONTACT)
                                }
                                .toAttrs()
                        ) {
                            Text("CONE-TACT US")
                        }
                    }
                }
            }
        }
    }

    if (uiState.imgPopup) {
        PicturePopup(
            imgURL = uiState.imgURL,
            onDismiss = {
                uiState = uiState.copy(imgPopup = false)
            }
        )
    }

}