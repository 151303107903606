package gr.web.icyleds.lkpktoolset.ContactForm

import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.JustifyContent
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.functions.min
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.focus
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.CSSAnimation

/*
    CONTACT FORM STYLES
*/
val contactFormContainer = CssStyle {
    base {
        Modifier
//            .width(min(900.px, 100.percent - 2.cssRem))
            .fillMaxWidth()
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .alignItems(AlignItems.Center)
            .padding(0.px)
    }
    Breakpoint.MD {
        Modifier
    }
}

val contactFormRow = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .alignItems(AlignItems.Center)
            .justifyContent(JustifyContent.Center)

            .fillMaxWidth()
            .margin(leftRight = autoLength)
            .gap(.5.px)
    }
    Breakpoint.MD {
        Modifier
            .flexDirection(FlexDirection.Row)
            .gap(1.cssRem)
    }
}

val contactRowColumn = CssStyle {
    base {
        Modifier
            .padding(topBottom = 2.5.px)
            .width(100.percent)
    }
    Breakpoint.MD {
        Modifier
    }
}

val inputLabelStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Block)
            .fontWeight(FontWeight.Light)
            .fontFamily("Gotham Font", "Sans-serif")
            .textTransform(TextTransform.Uppercase)

            .fontSize(1.3.cssRem)
            .color(Theme.White.rgb)
            .fontWeight(FontWeight.Bold)
    }
}

val inputStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .borderRadius(2.px)

            .fontSize(16.px)
            .height(40.px)
            .padding(10.px)
            .backgroundColor(Theme.White.rgb)
            .outline(
                width = 0.px,
                style = LineStyle.None,
                color = Colors.Transparent
            )
    }
}

val InputFocusStyle = CssStyle {
    base {
        Modifier
            .border(
                width = 2.px,
                style = LineStyle.Solid,
                color = Colors.Black
            )
            .borderRadius(5.px)
            .transition(Transition.of(property = "border", duration = 300.ms))
    }
    focus {
        Modifier
            .border(
                width = 3.px,
                style = LineStyle.Solid,
                color = Theme.IcyRed.rgb
            )
    }
}

val loadingSpinnerAnimation = Keyframes {
    0.percent {
        Modifier.transform {
            rotate(0.deg)
        }
    }
    100.percent {
        Modifier.transform {
            rotate(360.deg)
        }
    }
}

/*
    HONEY POT STYLES
*/
val HeyBear = CssStyle {
    base {
        Modifier
            .opacity(0)
            .position(Position.Absolute)
            .top(0.px)
            .left(0.px)
            .zIndex(-1)
            .size(0.px)
    }
}





