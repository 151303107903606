package gr.web.icyleds.styles

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.css.functions.toImage
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.toModifier
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Source
import org.jetbrains.compose.web.dom.Video

@Composable
fun VideoBackground(modifier: Modifier, content: @Composable () -> Unit) {
    Box(
        modifier = modifier
    ) {

        //Video Element
//        Video(
//            attrs = Modifier
//                .fillMaxSize()
//                .objectFit(ObjectFit.Cover)
//                .position(Position.Absolute)
//                .toAttrs{
//                    attr("autoplay", "true")
//                    attr("loop", "true")
//                    attr("muted", "true")
//                    attr("playsinline", "true")
//                }
//        ) {
//            Source(
//                attrs = Modifier
//                    .toAttrs{
//                        attr("src", RES.PLANT_VIDEO_BG)
//                        attr("type", "video/mp4")
//                    }
//            )
//        }

        Img(
            src = RES.PLANT_VIDEO_JPG,
            alt = "Plant Background",
            attrs = Modifier
                .fillMaxSize()
                .objectFit(ObjectFit.Cover)
                .position(Position.Absolute)
                .toAttrs()
        )

        //Vignette Overlay
        Box(
            modifier = Modifier
                .fillMaxSize()
                .position(Position.Absolute)
                .zIndex(1)
                .then(videoBackgroundOverlay.toModifier())
        )

        //Hero Content Above it
        content()
    }
}

val videoBackgroundOverlay = CssStyle {
    base {
        Modifier
            .background(
                Background.of(
                    image = linearGradient(LinearGradient.Direction.ToTop) {
                        add(Color.rgba(0, 0, 0, 0.15f), 60.percent)
                        add(Colors.Black, 100.percent)
                    }.toImage()
                ),
                Background.of(
                    image = linearGradient(LinearGradient.Direction.ToTop) {
                        add(rgba(0,0,0, 0.3), 100.percent)
                        add(Colors.Black, 100.percent)
                    }.toImage()
                ),
            )
    }
}