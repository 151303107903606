package gr.web.icyleds.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import gr.web.icyleds.lkpktoolset.ContactForm.*
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.RES.CHERRY_EXP
import gr.web.icyleds.util.RES.CHERRY_SUB
import gr.web.icyleds.util.Theme
import io.ktor.http.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLInputElement

val HoverCherrySubmitStyle = CssStyle {
    base {
        Modifier
            .transition(Transition.all(250.ms))
    }
    hover {
        Modifier
            .scale(1.05)
            .opacity(60.percent)
    }
}

val hiddenZipUtilityRow = CssStyle {
    base {
        Modifier
            .margin(top = (-50).px)
            .transition(Transition.all(250.ms))
    }
}

val animationZipUtilityRow = Keyframes {
    from {
        Modifier
            .margin(top = (-30).px)
            .opacity(0)
    }
    to {
        Modifier
            .margin(top = 0.px, bottom = 16.px)
            .opacity(100.percent)
    }
}

@Composable
fun newContactForm(scope: CoroutineScope) {
    var submitResponse by remember { mutableStateOf(" ") }
    var formSubmited by remember { mutableStateOf(false) }
    var rebatesSelected by remember { mutableStateOf(false) }

    //Using Form Submit Honeypot
//    var isBear  by remember { mutableStateOf(false ) }
//    bearHuntingForm { spotted ->
//        isBear = spotted
//    }

    Form(
        action = "https://formsubmit.co/dana@icyleds.com", //TODO Change to Dan or Dana
        attrs = Modifier.fillMaxWidth().toAttrs {
            onSubmit {
                val submitEvent = it
                submitEvent.preventDefault() // Prevent form submission if button is disabled

                val inputName = (document.getElementById(RES.FORMS.NAME) as HTMLInputElement).value
                val inputEmail = (document.getElementById(RES.FORMS.EMAIL) as HTMLInputElement).value

                if (
                    inputName.isNotBlank() && inputName.isNotEmpty() &&
                    inputEmail.isNotBlank() && inputEmail.isNotEmpty()
                ) {
                    formSubmited = true
                    submitResponse = "Thank you! We will contact you soon!"

                    scope.launch {
                        delay(2000)
                        (submitEvent.target as? HTMLFormElement)?.submit()
                    }
                } else {
                    submitResponse = "Please fill all required fields."
                }
            }
            attr("method", "POST")
            //attr("target", "_blank") //Not opening in new tab.
        }
    ) {
        //formsubmit config
        Input( //Email Subject
            type = InputType.Hidden,
            attrs = Modifier
                .toAttrs {
                    value("New ICY LEDS Contact!")
                    name("_subject")
                }
        )
        Input( //Link to after
            type = InputType.Hidden,
            attrs = Modifier
                .toAttrs {
                    value(window.location.href) //Just refreshes the tab basically.
                    name("_next")
                }
        )
        Input( //Turning off Captcha, since I got BEAR form here.
            type = InputType.Hidden,
            attrs = Modifier
                .toAttrs {
                    value("false")
                    name("_captcha")
                }
        )
        Input( //FormSubmit honeypot! Extra coverage with no Captcha.
            type = InputType.Text,
            attrs = Modifier
                .display(DisplayStyle.None)
                .toAttrs {
                    name("_honey")
                }
        )
        Input( //Better email template for viewing
            type = InputType.Hidden,
            attrs = Modifier
                .toAttrs {
                    value("table")
                    name("_template")
                }
        )

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .boxShadow(0.px, 10.px, 15.px, 8.px, rgba(0,0,0,0.1))
                .backgroundColor(Colors.WhiteSmoke)
                .padding(24.px)
                .borderRadius(10.px)
        ) {
            SimpleGrid(
                numColumns = numColumns(1, 2),
                modifier = Modifier
                    .gap(10.px)
                    .margin(bottom = 20.px)
                    .fillMaxSize()
            ) {
                Column(
                    modifier = Modifier
                ) {
                    Label(
                        forId = RES.FORMS.NAME,
                        attrs = inputLabelStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .toAttrs()
                    ) {
                        Text("Name")
                        SpanText(" *", Modifier.color(Theme.IcyPink.rgb))
                    }
                    Input(
                        type = InputType.Text,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .id(RES.FORMS.NAME)
                            .toAttrs {
                                name(RES.FORMS.NAME)
                            }
                    )
                }

                Column(
                    modifier = Modifier
                ) {
                    Label(
                        forId = RES.FORMS.EMAIL,
                        attrs = inputLabelStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .toAttrs()
                    ) {
                        Text("Email")
                        SpanText(" *", Modifier.color(Theme.IcyPink.rgb))
                    }
                    Input(
                        type = InputType.Email,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .id(RES.FORMS.EMAIL)
                            .toAttrs {
                                name(RES.FORMS.EMAIL)
                            }
                    )
                }

                Column(
                    modifier = Modifier
                ) {
                    Label(
                        forId = RES.FORMS.COMPANY_NAME,
                        attrs = inputLabelStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .toAttrs()
                    ) {
                        Text("COMPANY NAME")
                    }
                    Input(
                        type = InputType.Text,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .id(RES.FORMS.COMPANY_NAME)
                            .toAttrs {
                                name(RES.FORMS.COMPANY_NAME)
                            }
                    )
                }

                Column(
                    modifier = Modifier
                ) {
                    Label(
                        forId = RES.FORMS.PHONE_NUMBER,
                        attrs = inputLabelStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .toAttrs()
                    ) {
                        Text("Phone Number")
                    }
                    Input(
                        type = InputType.Text,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .id(RES.FORMS.PHONE_NUMBER)
                            .toAttrs {
                                name(RES.FORMS.PHONE_NUMBER)
                            }
                    )
                }
            }

            H3(
                attrs = Modifier
                    .fontSize(24.px)
                    .color(Theme.IcyGold.rgb)
                    .letterSpacing(1.px)
                    .margin(0.px)
                    .toAttrs()
            ) {
                Text("PRODUCT INTEREST")
            }

            HorizontalDivider(
                Modifier
                    .fillMaxWidth()
                    .color(Theme.IcyGold.rgb)
                    .margin(top = 5.px, bottom = 10.px)
                    .padding(0.px)
            )

            SimpleGrid(
                numColumns = numColumns(1, 3),
                modifier = Modifier
                    .gap(15.px)
                    .padding(
                        top = 5.px,
                        bottom = 20.px
                    ),
            ) {
                Row(
                    modifier = Modifier,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Input(
                        type = InputType.Checkbox,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .accentColor(Theme.IcyRed.rgb)
                            .margin(right = 5.px)
                            .width(20.px)
                            .height(20.px)
                            .id(RES.FORMS.INTEREST_ICY_BARS)
                            .toAttrs {
                                name(RES.FORMS.INTEREST_ICY_BARS)
                            }
                    )

                    Label(
                        forId = RES.FORMS.INTEREST_ICY_BARS,
                        attrs = inputLabelStyle.toModifier()
                            .lineHeight(18.px)
                            .color(Theme.IcyPink.rgb)
                            .toAttrs()
                    ) {
                        Text("ICY BARS")
                    }
                }

                Row(
                    modifier = Modifier,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Input(
                        type = InputType.Checkbox,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .accentColor(Theme.IcyRed.rgb)
                            .margin(right = 5.px)
                            .width(20.px)
                            .height(20.px)
                            .id(RES.FORMS.INTEREST_FAT_PACK)
                            .toAttrs {
                                name(RES.FORMS.INTEREST_FAT_PACK)
                            }
                    )

                    Label(
                        forId = RES.FORMS.INTEREST_FAT_PACK,
                        attrs = inputLabelStyle.toModifier()
                            .lineHeight(18.px)
                            .color(Theme.IcyPink.rgb)
                            .toAttrs()
                    ) {
                        Text("FAT PACK")
                    }
                }

                Row(
                    modifier = Modifier,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Input(
                        type = InputType.Checkbox,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .accentColor(Theme.IcyRed.rgb)
                            .margin(right = 5.px)
                            .width(20.px)
                            .height(20.px)
                            .id(RES.FORMS.INTEREST_ICY_POP)
                            .toAttrs {
                                name(RES.FORMS.INTEREST_ICY_POP)
                            }
                    )

                    Label(
                        forId = RES.FORMS.INTEREST_ICY_POP,
                        attrs = inputLabelStyle.toModifier()
                            .lineHeight(18.px)
                            .color(Theme.IcyPink.rgb)
                            .toAttrs()
                    ) {
                        Text("ICY POP")
                    }
                }
            }


            H3(
                attrs = Modifier
                    .fontSize(24.px)
                    .color(Theme.IcyGold.rgb)
                    .letterSpacing(1.px)
                    .margin(0.px)
                    .toAttrs()
            ) {
                Text("REBATE INTEREST")
            }

            HorizontalDivider(
                Modifier
                    .fillMaxWidth()
                    .color(Theme.IcyGold.rgb)
                    .margin(top = 5.px, bottom = 10.px)
                    .padding(0.px)
            )

            SimpleGrid(
                numColumns = numColumns(1, 3),
                modifier = Modifier
                    .gap(15.px)
                    .padding(
                        top = 5.px,
                        bottom = 20.px
                    ),
            ) {
                Row(
                    modifier = Modifier,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Input(
                        type = InputType.Checkbox,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .accentColor(Theme.IcyRed.rgb)
                            .margin(right = 5.px)
                            .width(20.px)
                            .height(20.px)
                            .id(RES.FORMS.INTEREST_REBATES)
                            .onClick {
                                rebatesSelected = !rebatesSelected
                            }
                            .toAttrs {
                                name(RES.FORMS.INTEREST_REBATES)
                            }
                    )

                    Label(
                        forId = RES.FORMS.INTEREST_REBATES,
                        attrs = inputLabelStyle.toModifier()
                            .lineHeight(18.px)
                            .color(Theme.IcyPink.rgb)
                            .toAttrs()
                    ) {
                        Text("REBATES")
                    }
                }
            }

            SimpleGrid(
                numColumns = numColumns(1, 2),
                modifier = hiddenZipUtilityRow.toModifier()
                    .display(if (rebatesSelected) DisplayStyle.Grid else DisplayStyle.None) //Uses Display.Grid
                    .animation(animationZipUtilityRow.toAnimation(
                        duration = 250.ms,
                        iterationCount = AnimationIterationCount.of(1)
                    ))
                    .gap(10.px)
                    .margin(bottom = 20.px)
                    .fillMaxSize()
            ) {
                Column(
                    modifier = Modifier
                ) {
                    Label(
                        forId = RES.FORMS.ZIP_CODE,
                        attrs = inputLabelStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .toAttrs()
                    ) {
                        Text("Zip Code")
                        SpanText("(Installation Location)", Modifier
                            .margin(left = 5.px)
                            .color(Colors.Black)
                            .fontWeight(FontWeight.Light)
                            .fontSize(16.px))
                    }
                    Input(
                        type = InputType.Text,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .id(RES.FORMS.ZIP_CODE)
                            .toAttrs{
                                name(RES.FORMS.ZIP_CODE)
                            }
                    )
                }

                Column(
                    modifier = Modifier
                ) {
                    Label(
                        forId = RES.FORMS.UTILITY,
                        attrs = inputLabelStyle.toModifier()
                            .color(Theme.IcyGold.rgb)
                            .toAttrs()
                    ) {
                        Text("UTILITY NAME")
                    }
                    Input(
                        type = InputType.Text,
                        attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                            .id(RES.FORMS.UTILITY)
                            .toAttrs {
                                name(RES.FORMS.UTILITY)
                            }
                    )
                }
            }

            TextArea(
                attrs = inputStyle.toModifier().then(InputFocusStyle.toModifier())
                    .id(RES.FORMS.DESCRIPTION)
                    .margin(top = 1.cssRem)
                    .height(120.px)
                    .fillMaxWidth()
                    .resize(Resize.None)
                    .fontSize(14.px)
                    .color(Colors.Black)
                    .toAttrs{
                        name(RES.FORMS.DESCRIPTION)
                        placeholder("Tell Us About Your Grow...")
                    }
            )

            Column(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                SpanText(
                    text = submitResponse,
                    modifier = Modifier.fontWeight(FontWeight.Light).fontSize(24.px).textAlign(TextAlign.Center)
                        .color(Colors.Black).margin(top = 10.px)
                )

                Button(
                    type = ButtonType.Submit,
                    modifier = Modifier
                        .position(Position.Relative)
                        .height(110.px)
                        .width(150.px)
                        .backgroundColor(Colors.Transparent)
                        .border(0.px, LineStyle.None, Colors.Transparent)
                        .outline(0.px, LineStyle.None, Colors.Transparent),
                    onClick = {}
                ) {
                    Img(
                        src = RES.CHERRY_EXP,
                        alt = "SUBMITTED",
                        attrs = Modifier
                            .position(Position.Absolute)
                            .opacity(if (formSubmited) 100 else 0)
                            .objectFit(ObjectFit.Contain)
                            .height(125.px)
                            .width(160.px)
                            .toAttrs()
                    )
                    Img(
                        src = CHERRY_SUB,
                        alt = "SUBMIT",
                        attrs = Modifier
                            .then(HoverCherrySubmitStyle.toModifier())
                            .position(Position.Absolute)
                            .opacity(if (formSubmited) 0 else 100)
                            .objectFit(ObjectFit.Contain)
                            .height(125.px)
                            .width(160.px)
                            .toAttrs()
                    )
                }
            }
        }

        SpanText(
            text = "By submitting this form, you are consenting to receive marketing emails from ICY.",
            modifier = Modifier
                .display(DisplayStyle.Block)
                .width(Width.FitContent)
                .margin(leftRight = autoLength)
                .fontWeight(FontWeight.Light)
                .fontStyle(FontStyle.Italic)
                .fontSize(18.px)
                .textAlign(TextAlign.Center)
                .color(Colors.Black)
        )
    }
}

