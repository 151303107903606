package gr.web.icyleds.components.sections

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import gr.web.icyleds.SitePalettes
import gr.web.icyleds.components.widgets.candyShop
import gr.web.icyleds.util.RES
import gr.web.icyleds.util.ROUTES
import gr.web.icyleds.util.Theme
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.dom.Div


//Stupid bootstrap overwrites Anchor tags with their own. Cant use style
//to rid of underline/blue text color for link. Need to inline style it.
val FooterStyle = CssStyle.base {
    Modifier
        .backgroundColor(Theme.IcyPurple.rgb)
        .padding(top = 4.cssRem, bottom = 1.5.cssRem, leftRight = 2.percent)
        .fillMaxWidth()
}

val FooterLinkSection = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .gap(2.cssRem)
    }
    Breakpoint.MD {
        Modifier
            .flexDirection(FlexDirection.Row)

    }
}

@Composable
fun FooterLink(tolink: String, Text: String) {
    Link(tolink, FooterLinkStyle.toModifier()
        .textDecorationLine(TextDecorationLine.None)
        .color(Colors.White)) {
        SpanText(Text, FooterTextStyle.toModifier())
    }
}

val FooterLinkStyle = CssStyle.base {
    Modifier
        .textDecorationLine(TextDecorationLine.None)
        .color(Colors.White)
}

val FooterColumnStyle = CssStyle {
    base {
        Modifier
            .display(DisplayStyle.Flex)
            .flexDirection(FlexDirection.Column)
            .alignItems(AlignItems.Start)
            .textAlign(TextAlign.Start)
            .gap(3.px)
            .fillMaxWidth()
    }
}

val FooterTitleStyle = CssStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontWeight(FontWeight.Bold)
            .margin(bottom = 1.cssRem)
            .fontSize(1.2.cssRem)
    }
}

val FooterTextStyle = CssStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontWeight(FontWeight.Light)
            .fontSize(.9.cssRem)
            .cursor(Cursor.Pointer)
    }
}

@Composable
fun iceCreamColumn() {
    val ctx = rememberPageContext()

    Row(Modifier.fillMaxWidth().gap((-2).cssRem), horizontalArrangement = Arrangement.Center) {
        Div(Modifier
            .backgroundImage(url(RES.ICE_CREAM_SVG))
            .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .backgroundSize(BackgroundSize.of(5.em))
            .height(5.em)
            .width(4.em)
            .toAttrs()) {

                FaFacebookF(Modifier
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        ctx.router.navigateTo(ROUTES.COMING_SOON)
                    }
                    .position(Position.Relative)
                    .top(8.px)
                    .left(28.px))
        }

        Div(Modifier
            .backgroundImage(url(RES.ICE_CREAM_SVG))
            .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .backgroundSize(BackgroundSize.of(5.em))
            .height(5.em)
            .width(4.em)
            .toAttrs()) {

            FaX(Modifier
                .color(Colors.White)
                .cursor(Cursor.Pointer)
                .onClick {
                    ctx.router.navigateTo(ROUTES.COMING_SOON)
                }
                .position(Position.Relative)
                .top(8.px)
                .left(28.px))
        }

        Div(Modifier
            .backgroundImage(url(RES.ICE_CREAM_SVG))
            .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
            .backgroundRepeat(BackgroundRepeat.NoRepeat)
            .backgroundSize(BackgroundSize.of(5.em))
            .height(5.em)
            .width(4.em)
            .toAttrs()) {

            FaInstagram(Modifier
                .color(Colors.White)
                .cursor(Cursor.Pointer)
                .onClick {
                    ctx.router.navigateTo(ROUTES.COMING_SOON)
                }
                .fontSize(1.2.em)
                .position(Position.Relative)
                .top(8.px)
                .left(26.px))
        }
    }
}

@Composable
fun Footer(modifier: Modifier = Modifier) {
    var musicPlaying by remember { mutableStateOf(false) }

    Column(FooterStyle.toModifier().then(modifier).gap(1.3.cssRem)) {
        Div(attrs = FooterLinkSection.toModifier().toAttrs()) {
            Column(FooterColumnStyle.toModifier().alignItems(AlignItems.Center).gap(2.cssRem)) {
                Image(RES.ICY_LOGO, "ICY Logo", Modifier.height(8.cssRem).display(DisplayStyle.Block)
                .onClick {
                    musicPlaying = !musicPlaying
                    candyShop(musicPlaying)
                })
                iceCreamColumn()
            }
            Column(FooterColumnStyle.toModifier()) {
                SpanText("Company Info", FooterTitleStyle.toModifier())
                FooterLink(ROUTES.OUR_SCOOP, "Our Scoop")
                FooterLink(ROUTES.OUR_FLAVORS, "Our Flavors")
                FooterLink("/#contact-us", "Contact Us")
                FooterLink("/privacy-policy", "Privacy Policy")
                FooterLink("/terms-of-service", "Terms and Conditions")
            }
            Column(FooterColumnStyle.toModifier()) {
                SpanText("Products", FooterTitleStyle.toModifier())
                FooterLink(ROUTES.PRODUCTS.ICY_POP, "ICY POP 120W Under Canopy LED Grow Light")
                FooterLink(ROUTES.PRODUCTS.ICY_BARS, "ICY BARS 820W LED Grow Light")
                FooterLink(ROUTES.PRODUCTS.FAT_PACK820, "ICY FAT PACK 820W LED Grow Light")
                FooterLink(ROUTES.PRODUCTS.FAT_PACK1640, "ICY FAT PACK 1640W LED Grow Light")
            }
            Column(FooterColumnStyle.toModifier()) {
                SpanText("Contact Us", FooterTitleStyle.toModifier())
                Div() {
                    FaLocationDot(Modifier.margin(right = .5.cssRem).color(Colors.Pink))
                    SpanText("300 E 2nd st, Ste 1510, Reno, NV 89501", FooterTextStyle.toModifier())
                }
                Div() {
                    FaPhone(Modifier.margin(right = .5.cssRem).color(Colors.Pink))
                    SpanText("775-235-2633", FooterTextStyle.toModifier())
                }
            }
        }
        HorizontalDivider(Modifier.color(SitePalettes.light.brand.pinkAccent))
        Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center) {
            SpanText("© 2025, ICY. All Rights Reserved.", Modifier.color(Colors.White))
        }
    }

}
